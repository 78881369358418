import {Button, Icon} from '@material-tailwind/react';
import history from '../components/history';

export default function BackButton() {
    return (
        <div className="absolute top-0 left mt-2 ml-2">
            <Button className="w-fit" onClick={(e) => {e.preventDefault(); history.goBack()}}>
                <Icon name="arrow_back" size="4xl" color="white" />
            </Button>
        </div>
    )
}
