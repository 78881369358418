import React from 'react';
import {Card, CardHeader, CardBody} from '@material-tailwind/react';

import MyModalCreateEleve from './EleveModal/MyModalCreateEleve';
import MyModalDeleteEleve from './EleveModal/MyModalDeleteEleve';
import MyModalModifEleve from './EleveModal/MyModalModifEleve';

import { useSelector } from 'react-redux'

export default function CardTable(props) {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalIsOpen2, setIsOpen2] = React.useState(false);
    const [modalIsOpen3, setIsOpen3] = React.useState(false);

    const [eleveSelect, setEleveSelect] = React.useState('');

    const allEleve = useSelector(state => state.eleveDetail.arr)
    var eleve = allEleve

    var position = props.position !== undefined && props.position !== null ? props.position.trim().split(',').map( Number ) : ''

    const sortEleves = (array, sortArray) => {
      return [...array].sort(
        (a, b) => sortArray.indexOf(a.id_eleve) - sortArray.indexOf(b.id_eleve)
      )
    }

    if (props.position !== undefined && props.position !== null) {
        eleve = sortEleves(eleve, position)
    }

    function openFromParent(modalNumber, row) {
        switch(modalNumber) {
          case 1:
            setIsOpen(true);
            break;
          case 2:
            setIsOpen2(true);
            setEleveSelect(row);
            break;
          case 3:
            setIsOpen3(true);
            setEleveSelect(row);
            break;
          default:
            break;
        }
    }

    function handleCloseModal(event, data) {
        switch(data) {
          case 1:
            setIsOpen(false);
            break;
          case 2:
            setIsOpen2(false);
            break;
          case 3:
            setIsOpen3(false);
            break;
          default:
            break;
        }
    }

    return (
        <div>
            <Card>
                <MyModalCreateEleve
                    IsModalOpened={modalIsOpen}
                    onCloseModal={handleCloseModal}
                    classeInfo={props}
                    key={Math.random()}
                />
                <MyModalModifEleve
                    IsModalOpened={modalIsOpen2}
                    onCloseModal={handleCloseModal}
                    eleveInfo={eleveSelect}
                    classeInfo={props}
                    key={Math.random()}
                />
                <MyModalDeleteEleve
                    IsModalOpened={modalIsOpen3}
                    onCloseModal={handleCloseModal}
                    eleveInfo={eleveSelect}
                    classeInfo={props}
                    key={Math.random()}
                />

                <CardHeader color="green" contentPosition="left">
                    <h2 className="text-white text-2xl">Liste des élèves</h2>
                </CardHeader>
                <CardBody>
                    <div className="overflow-x-auto">
                        {eleve.length > 0 ?
                            <table className="items-center w-full bg-transparent border-collapse">
                                <thead>
                                    <tr>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Prénom
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Nom
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Niveau(x)
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                     {eleve.map( row => {
                                        return (
                                            <tr key={row.id_eleve}>
                                                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    { row.prenom }
                                                </th>
                                                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    { row.nom }
                                                </th>
                                                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    { row.niveau }
                                                </th>
                                                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    <div className="flex">
                                                        <div className="w-10 h-10 rounded-full border-2 border-white" style={{ height: 'auto', width: 'fit-content' }}>
                                                            <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-4 mb-1 rounded" style={{ width: '100%', display: 'inline' }} onClick={(e) => {openFromParent(2, row)}}>Modifier</button>
                                                            <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 mb-1 rounded" style={{ width: '100%', display: 'inline' }} onClick={(e) => {openFromParent(3, row)}}>Retirer</button>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        :
                            <span>Vous n'avez aucun élève</span>
                        }
                        <div className="flex mt-12">
                            <div className="rounded-full border-2 border-white">
                                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-4 rounded" onClick={(e) => {openFromParent(1)}}>Ajouter un élève</button>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
