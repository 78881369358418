import React from 'react';
import axios from 'axios';
import history from '../components/history';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux'
import Logo from 'assets/img/Logo_EPS_300.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-regular-svg-icons'
import { faEyeSlash } from '@fortawesome/free-regular-svg-icons'

import { NotificationManager } from 'react-notifications';

const cookies = new Cookies();

const LoginForm = ({loginSubmit}) => {
  let email;
  let password;

  const dispatch = useDispatch()

  const [showPasswordValue, setShowPasswordValue] = React.useState(false);

  function showPassword() {
    setShowPasswordValue(!showPasswordValue)
  }

  return (
    <div className="App">
      <header className="header">
        <div className="flex flex-wrap justify-center">
          <img
          src={Logo}
          className="mt-10 mb-10"
          alt="..."
        />
        </div>
      </header>

      <form className="container mx-auto">
        <div className="grid justify-items-center mb-6">
          <div className="">
            <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
              E-mail
            </label>
          </div>
          <div className="md:w-2/3">
            <input className="bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-grey-500" id="inline-full-name" type="text" placeholder="Email" ref={loginValue => {
              email = loginValue;
            }} />
          </div>
        </div>
        <div className="grid justify-items-center mb-6">
          <div className="">
            <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-password">
              Mot de passe
            </label>
          </div>
          <div className="md:w-2/3" style={{ display: 'flex' }}>
            <input className="bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-grey-500" id="inline-password" type={showPasswordValue === false ? 'password' : 'text'} placeholder="Password" ref={passwordValue => {
              password = passwordValue;
            }} />
            <button type="button" onClick={(e) => { e.preventDefault(); showPassword() }} className="bg-gray-200 appearance-none border-2 border-gray-200 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-grey-500">{showPasswordValue === false ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} /> }</button>
          </div>
        </div>
        <div className="grid justify-items-center mb-6">
          <div className="">
            <button className="w-80 shadow bg-green-500 hover:bg-green-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit"
            onClick={(e) => {
              e.preventDefault();
              loginSubmit(email.value,password.value,dispatch);
              email.value = '';
              password.value = '';
            }}>
              Valider
            </button>
          </div>
        </div>
        <div className="grid justify-items-center mb-6">
          <div className="">
            <button className="w-80 shadow bg-blue-900 hover:bg-blue-800 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="button"
            onClick={(e) => {
              e.preventDefault();
              history.push('/change_mdp');
              history.go('/change_mdp');
            }}>
              Mot de passe oublié
            </button>
          </div>
        </div>
      </form>
      {/*<div className="flex flex-wrap justify-center">
        <a href="https://apps.apple.com/vn/app/acc%C3%A8s-eps/id1608875052" target="blank" alt="logo apple store">
          <img
            src={Store1}
            className="mt-10 mb-10 mr-10 ml-10 w-24"
            alt="..."
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.acceseps.app" target="blank" alt="logo google store">
          <img
            src={Store2}
            className="mt-10 mb-10 mr-10 ml-10 w-24"
            alt="..."
          />
        </a>
      </div>*/}
    </div>
  );
};

const loginSubmit = (email,password,dispatch) => {
    const formData = new FormData();

    formData.append('email', email)
    formData.append('password', password)

    axios.post('https://eps.acces-editions.com/api/loginAppli', formData)
    .then((res) => {
      if(res.data.data[0] !== undefined && res.data.data[0].email)
      {
        if(res.data.session.authenticated === true)
        {
          dispatch({ type: 'user/userInfo', payload: {id_compte: res.data.data[0].id_compte, email: res.data.data[0].email, admin: false} })
          cookies.remove('authenticated')
          cookies.remove('authenticated_admin')
          cookies.set('authenticated', 'true', { path: '/', maxAge: '3600' });
          cookies.set('statut', res.data.data[0].statut, { maxAge: '3600' });
          history.push('/compte');
          history.go('/compte');
        }
      } else {
        NotificationManager.error("E-mail ou mot de passe invalide", '', 3000);
      }
    });
  }

const LoginClass = () => {  
  return (
    <LoginForm loginSubmit={loginSubmit}/>
  );
}

export default LoginClass;