import React, { useState } from 'react';

import { Card, CardHeader, CardBody, Icon, Button } from '@material-tailwind/react';

import CreatePdfFilAnnuel from 'components/CreatePdfFilAnnuel';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { NotificationManager } from 'react-notifications';

import JsPDF from 'jspdf';

import axios from 'axios';

import { registerLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr';

registerLocale('fr', fr);

export default function ClasseFilAnnuel({idClasse}) {

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [allSeance, setAllSeance] = useState([]);

    const setStartOfDay = () => {
        const startOfDay = new Date();
        startOfDay.setHours(0, 0, 0, 0); // Sets the time to 23:59:59.999
        setStartDate(startOfDay);
    };

    const setEndOfDay = () => {
        const endOfDay = new Date();
        endOfDay.setHours(23, 59, 59, 999); // Sets the time to 23:59:59.999
        setEndDate(endOfDay);
    };

    function uppercaseFirstChar(str) {
      if (!str) return str; // Return the original string if it's empty or undefined
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    React.useEffect(() => {
        setStartOfDay();
        setEndOfDay();
    }, []);

    function getFilAnnuel(idClasse, startDate, endDate) {
        const formData = new FormData();
        formData.append('id_classe', idClasse)
        formData.append('date_start', startDate)
        formData.append('date_end', endDate)

        axios.post('https://eps.acces-editions.com/api/filAnnuel', formData)
        .then((res) => {
            if(res.data.length > 0)
            {
                setAllSeance(res.data)
            } else {
                NotificationManager.error('Aucun fil pour ces dates !', '', 3000);
            }
        })
    }

    function generatePDF(allSeance) {
        const report = new JsPDF('p','pt','a4');

        report.html(document.querySelector('#fil_annuel')).then(() => {
            report.save('fil_annuel.pdf');
        });
    }

    return (
        <div>
            {allSeance.length > 0 ?
                <CreatePdfFilAnnuel idClasse={idClasse} allSeance={allSeance} startDate={startDate} endDate={endDate} />
                :
                <div></div>
            }
            <Card className="mt-12">
                <CardHeader color="purple" contentPosition="left">
                    <h2 className="text-white text-2xl">Fil annuel</h2>
                </CardHeader>
                <CardBody>
                    <div className="overflow-x-auto">
                        <div className="mb-8"><p className="text-gray-400">Synthèse des disciplines abordées pour lesquelles il y a eu au moins une évaluation d'activité.</p></div>
                        <div className="flex">
                            <div className="inline-flex">
                                <span className="pr-4 flex justify-center items-center">Du :</span>
                                <DatePicker locale="fr" selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="dd/MM/yyyy" className="border-2 border-purple-500 rounded-lg p-2" />
                                <span className="align-middle"><Icon name="calendar_today" size="4xl" color="black"/></span>
                            </div>
                            <div className="pl-8 inline-flex">
                                <span className="pr-4 flex justify-center items-center">Au :</span>
                                <DatePicker locale="fr" selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="dd/MM/yyyy" className="border-2 border-purple-500 rounded-lg p-2" />
                                <span className="align-middle"><Icon name="calendar_today" size="4xl" color="black"/></span>
                            </div>
                            <Button type="button" className="bg-blue-700 ml-8" onClick={(e) => {getFilAnnuel(idClasse, startDate, endDate)}}><Icon name="search" size="xl" color="white" /></Button>
                            {allSeance.length > 0 ?
                                    <div className="ml-auto">
                                        <Button type="button" className="bg-purple-500" onClick={(e) => {e.preventDefault(); generatePDF(allSeance)}}><Icon name="picture_as_pdf" size="xl" color="white" />Export PDF</Button>
                                    </div>
                                :
                                    <div></div>
                            }
                        </div>
                        <div className="mt-8">
                            {allSeance.length > 0 ?
                                <table className="items-center w-full bg-transparent border-collapse">
                                    <thead>
                                        <tr>
                                            <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                                <span className="font-medium">Cycle</span>
                                            </th>
                                            <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                                <span className="font-medium">Champ d'apprentissage</span>
                                            </th>
                                            <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                                <span className="font-medium">Discipline</span>
                                            </th>
                                            <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 whitespace-nowrap font-light text-center">
                                                <span className="font-medium">Nombre d'activités évaluées</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                         {allSeance.map((row, index) => {
                                            return (
                                                <tr key={row.id_cycle + Math.random()}>
                                                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                        <span className="font-normal">{row.id_cycle}</span>
                                                    </th>
                                                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                        <span className="font-normal">{uppercaseFirstChar(row.nom_competence)}</span>
                                                    </th>
                                                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                        <span className="font-normal">{row.nom_sequence}</span>
                                                    </th>
                                                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-center">
                                                        <span className="bg-purple-500 text-white p-2 rounded-full w-10 h-10 inline-flex justify-center items-center font-bold">{row.nbSeance}</span>
                                                    </th>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                :
                                <div>
                                </div>
                            }
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
