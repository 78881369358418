import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from '@material-tailwind/react';

import { useSelector, useDispatch } from 'react-redux'

import EnterSend from "../EnterSend";

import { NotificationManager } from 'react-notifications';

import axios from 'axios';

function MyModalDeleteClasse(props) {

  function onModalClose(event) {
    props.onCloseModal(event, 4);
  }

  const [idClasse, setIdClasse] = React.useState();
  const [nomClasse, setNomClasse] = React.useState();
  const [nomEtablissement, setNomEtablissement] = React.useState();
  const [niveauClasse, setNiveauClasse] = React.useState(0);

  const user_connected = useSelector(state => state.userConnected.user_connected)
  const compte = useSelector(state => state.userGlobal.user)

  const handleSubmit = () => {
    if(props.IsModalOpened === true) {
      deleteClasse()
    }
  };

  React.useEffect(() => {
    setIdClasse(props.classe.id_classe)
    setNomClasse(props.classe.nom_classe)
    setNomEtablissement(props.classe.nom_etablissement)
    setNiveauClasse(props.classe.niveau)
  }, [props.classe.id_classe, props.classe.nom_classe, props.classe.nom_etablissement, props.classe.niveau]);

  const dispatch = useDispatch()

  function deleteClasse() {
    const params = {
      id_classe: idClasse,
      id_compte: compte.id_compte,
      id_user: user_connected.id_user,
    };
    
    axios.delete('https://eps.acces-editions.com/api/removeMyClasse', { params })
    .then((res) => {
      axios.get('https://eps.acces-editions.com/api/getMyClasse?id_compte='+compte.id_compte+'&id_user='+user_connected.id_user)
      .then((res) => {
        if(res.data[0] !== undefined && res.data[0].id_classe)
        {
          NotificationManager.success('Classe supprimée', '', 3000);
          dispatch({ type: 'user/classeDetail', payload: res.data});
          onModalClose(false);
        }
      })
    })
  }


  return (
    <>
      <Modal size="sm" active={props.IsModalOpened} toggler={() => {return}}>
        <ModalHeader toggler={() => onModalClose(false)}>
            Supprimer la classe
        </ModalHeader>
        <ModalBody>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Nom de la classe
            </label>
            <span>{nomClasse}</span>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Nom de l'établissement
            </label>
            <span>{nomEtablissement}</span>
            </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Niveaux
            </label>
            <span>{niveauClasse}</span>
          </div>
        </ModalBody>
        <ModalFooter>
            <Button 
                color="red"
                buttonType="link"
                onClick={(e) => onModalClose(false)}
                ripple="dark"
            >
                Fermer
            </Button>

            <Button
                color="green"
                onClick={(e) => deleteClasse()}
                ripple="light"
            >
              <EnterSend handleSubmit={handleSubmit}/>
              Supprimer
            </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default MyModalDeleteClasse;