import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from '@material-tailwind/react';

import EnterSend from "../EnterSend";

import { useSelector, useDispatch } from 'react-redux'

import { NotificationManager } from 'react-notifications';

import axios from 'axios';

function MyModalDeleteDevice({IsModalOpened, onCloseModal, deviceInfo, deviceNumberParams}) {

  function onModalClose(event) {
    onCloseModal(event, 7);
  }

  const user = useSelector(state => state.userGlobal.user)
  const user_connected = useSelector(state => state.userConnected.user_connected)

  const dispatch = useDispatch()

  const [deviceName, setDeviceName] = React.useState('');
  const [macAdress, setMacAdress] = React.useState('');
  const [codeUnique, setCodeUnique] = React.useState('');

  const handleSubmit = () => {
    if(IsModalOpened === true) {
      saveModif()
    }
  };

  React.useEffect(() => {
    switch(deviceNumberParams) {
      case 1:
        setDeviceName(deviceInfo.device_name_1)
        setMacAdress(deviceInfo.mac_adress_1)
        break;
      case 2:
        setDeviceName(deviceInfo.device_name_2)
        setMacAdress(deviceInfo.mac_adress_2)
        break;
      default:
        break;
    }
  }, [deviceNumberParams, deviceInfo.device_name_1, deviceInfo.device_name_2, deviceInfo.mac_adress_1, deviceInfo.mac_adress_2]);

  function sendCodeUnique() {
    const formData = new FormData();

    formData.append('email', user.email)
    formData.append('id_user', user_connected.id_user)
    formData.append('id_compte', deviceInfo.id_compte)

    axios.post('https://eps.acces-editions.com/api/deleteMacAdressCreateCode', formData)
    .then((res) => {
      if(res.data !== undefined)
      {
        NotificationManager.success("Un e-mail vous a été envoyé", '', 3000);
      }
    })
  }

  function saveModif() {
    if(codeUnique.length !== 12) {
      NotificationManager.error("Votre code unique n'est pas valide", '', 3000);
      return
    }

    const formData = new FormData();

    formData.append('id_compte', deviceInfo.id_compte)
    formData.append('id_user', user_connected.id_user)
    formData.append('code_unique', codeUnique)

    switch(deviceNumberParams) {
      case 1:
        formData.append('mac_adress_number', 'mac_adress_1')
        formData.append('device_name_number', 'device_name_1')

        break;
      case 2:
        formData.append('mac_adress_number', 'mac_adress_2')
        formData.append('device_name_number', 'device_name_2')
        break;
      default:
        break;
    }
    
    axios.post('https://eps.acces-editions.com/api/deleteMacAdress', formData)
    .then((res) => {
      axios.get('https://eps.acces-editions.com/api/getUser?id_user='+user_connected.id_user)
      .then((res) => {
        if(res.data.allInfo[0] !== undefined && res.data.allInfo[0].id_compte)
        {
          NotificationManager.success("Appareil dissocié du compte", '', 3000);
          dispatch({ type: 'user/userDevice', payload: res.data.allInfo[0]})
          onModalClose(false);
        }
      })
    })
  }

  return (
    <>
      <Modal size="sm" active={IsModalOpened} toggler={() => {return}}>
        <ModalHeader toggler={() => onModalClose(false)}>
            Dissocier un appareil
        </ModalHeader>
        <ModalBody>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Nom de l'appareil
            </label>
            <span>{deviceName}</span>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Adresse MAC
            </label>
            <span>{macAdress}</span>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Code unique
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type='text' value={codeUnique} onInput={(e) => { setCodeUnique(e.target.value) }} />
          </div>
          <Button color="blue" onClick={(e) => sendCodeUnique()} ripple="dark">recevoir mon code unique</Button>
        </ModalBody>
        <ModalFooter>
            <Button 
                color="red"
                buttonType="link"
                onClick={(e) => onModalClose(false)}
                ripple="dark"
            >
                Fermer
            </Button>

            <Button
                color="green"
                onClick={(e) => saveModif()}
                ripple="light"
            >
              <EnterSend handleSubmit={handleSubmit}/>
              Supprimer
            </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default MyModalDeleteDevice;