const initialState = { eleve: {nb_eleve_note: 0 }}

export default function eleveNbNoteReducer(state = initialState, action) {
  switch (action.type) {
    case 'note/eleveNbNoteDetail': {
      // Can return just the new todos array - no extra object around it
      return {
        ...state,
        eleve: {
          nb_eleve_note: action.payload,
        }
      }
    }
    default:
      return state
  }
}