import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Image} from '@material-tailwind/react';

import EnterSend from "./EnterSend";

import { NotificationManager } from 'react-notifications';

import { useSelector, useDispatch } from 'react-redux'

import axios from 'axios';

function MyModalComponent2(props) {

  function onModalClose(event) {
    props.onCloseModal(event, 2);
  }

  const [pseudo, setPseudo] = React.useState(props.user.pseudo);
  const [idAvatar, setIdAvatar] = React.useState(props.user.id_avatar);
  const [codePin, setCodePin] = React.useState(0);

  const dispatch = useDispatch()

  const user = useSelector(state => state.userGlobal.user)

  const handleSubmit = () => {
    if(props.IsModalOpened === true) {
      saveModif(pseudo, idAvatar, codePin)
    }
  };

  React.useEffect(() => {
    setPseudo(props.user.pseudo)
    setIdAvatar(props.user.id_avatar)
  }, [props.user.pseudo, props.user.id_avatar]);

  function saveModif(pseudo, id_avatar, code_pin) {
    const formData = new FormData();
    formData.append('id_compte', user.id_compte)
    formData.append('id_user', props.user.id_user)

    axios.post('https://eps.acces-editions.com/api/matchPin', formData)
    .then((res) => {
        if(res.data.results[0] !== undefined && res.data.results[0].pin_code)
        {
            if(res.data.results[0].pin_code.toString() === code_pin.toString()) {
              NotificationManager.success('Vous êtes connecté', '', 3000);
              dispatch({ type: 'user/userConnected', payload: {id_user: props.user.id_user, connected: true, id_avatar: props.user.id_avatar, pseudo: props.user.pseudo} })
              onModalClose(false)
              axios.get('https://eps.acces-editions.com/api/getMyClasse?id_compte='+user.id_compte+'&id_user='+props.user.id_user)
              .then((res) => {
                if(res.data[0] !== undefined && res.data[0].id_classe)
                {
                  dispatch({ type: 'user/classeDetail', payload: res.data})
                }
              })
              axios.get('https://eps.acces-editions.com/api/getUser?id_user='+props.user.id_user)
              .then((res) => {
                if(res.data.allInfo[0] !== undefined && res.data.allInfo[0].id_compte)
                {
                  dispatch({ type: 'user/userDevice', payload: res.data.allInfo[0]})
                }
              })
            } else {
              NotificationManager.warning('Code secret invalide', '', 3000);
            }
        }
    })
  }

  return (
    <div>
      <Modal size="sm" active={props.IsModalOpened} toggler={() => {return}}>
        <ModalHeader toggler={() => onModalClose(false)}>
            Connexion
        </ModalHeader>
        <ModalBody>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2 flex justify-center items-center" htmlFor="username">
              {pseudo}
            </label>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2 flex justify-center items-center" htmlFor="username">
              <Image
                src={process.env.PUBLIC_URL + `/img/avatars/avatar_${idAvatar}.png`}
                rounded
                alt="avatar"
              />
            </label>
            <div>
            </div>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Valider avec le code secret à 4 chiffres
            </label>
            <input id="pin" maxLength="4" autoComplete="new-password" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type='password' onChange={(e) => { setCodePin(e.target.value)}} />
          </div>
        </ModalBody>
        <ModalFooter>
            <Button 
                color="red"
                buttonType="link"
                onClick={(e) => onModalClose(false)}
                ripple="dark"
            >
                Fermer
            </Button>
            <Button
                color="green"
                onClick={(e) => saveModif(pseudo, idAvatar, codePin)}
                ripple="light"
            >
                <EnterSend handleSubmit={handleSubmit}/>
                Connexion
            </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default MyModalComponent2;