const initialState = { user: {id_compte: 0, email: '', admin: false }}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'user/userInfo': {
      // Can return just the new todos array - no extra object around it
      return {
        ...state,
        user: {
          id_compte: action.payload.id_compte,
          email: action.payload.email,
          admin: action.payload.admin,
        }
      }
    }
    default:
      return state
  }
}