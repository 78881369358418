import { Switch, Route, Redirect } from 'react-router-dom';
import Sidebar from 'components/Sidebar';
import ScrollToTop from 'components/ScrollToTop';
import ForgotPassword from 'pages/ForgotPassword';
import DeleteAccount from 'pages/DeleteAccount';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import Tables from 'pages/Tables';
import Classe from 'pages/Classe';
import Suivi from 'pages/Suivi'
import FilAnnuel from 'pages/FilAnnuel'
import Note from 'pages/Note';
import { NotificationContainer } from 'react-notifications';
import history from 'components/history';

//ADMIN PAGE
import SideBarAdmin from 'components/SideBarAdmin';
import LoginAdmin from 'pages/LoginAdmin';
import Dashboard from 'pages/Dashboard';

// Tailwind CSS Style Sheet
import 'assets/styles/tailwind.css';
import "@material-tailwind/react/tailwind.css";
import 'react-notifications/lib/notifications.css';

import Cookies from 'universal-cookie';

function App() {

    const cookies = new Cookies();

    const fakeAuth = {
      isAuthenticated: cookies.get('authenticated')
      ,isAuthenticatedAdmin: cookies.get('authenticated_admin')
      ,rules: cookies.get('rules')
      ,filter: cookies.get('filter')
    }

    return (
        <>
            {fakeAuth.isAuthenticated === 'true' && (<Sidebar />)}
            {fakeAuth.isAuthenticatedAdmin === 'true' && (<SideBarAdmin />)}

            <div>
                <NotificationContainer />
                <ScrollToTop history={history}>
                    <Switch>
                        <Route exact path="/" component={Login} />
                        <Route exact path="/admin_dashboard" component={LoginAdmin} />
                        <Route exact path="/logout" component={Logout} />
                        <Route exact path="/change_mdp" component={ForgotPassword} />
                        <Route exact path="/delete_account" component={DeleteAccount} />

                        {fakeAuth.isAuthenticated === 'true' && (<Route exact path="/compte" component={Tables} />)}
                        {fakeAuth.isAuthenticated === 'true' && (<Route exact path="/classe" component={Classe} />)}
                        {fakeAuth.isAuthenticated === 'true' && (<Route exact path="/note" component={Note} />)}
                        {fakeAuth.isAuthenticated === 'true' && (<Route exact path="/suivi_eval" component={Suivi} />)}
                        {fakeAuth.isAuthenticated === 'true' && (<Route exact path="/fil_annuel" component={FilAnnuel} />)}

                        {fakeAuth.isAuthenticatedAdmin === 'true' && (<Route exact path="/dashboard" component={Dashboard} />)}
                        {/*{fakeAuth.isAuthenticatedAdmin === 'true' ? <Route exact path="/settings" component={Settings} /> : <Redirect from="*" to="/" />}*/}
                        {/*{fakeAuth.isAuthenticatedAdmin === 'true' ? <Route exact path="/maps" component={Maps} /> : <Redirect from="*" to="/" />}*/}
                        <Redirect from="*" to="/" />
                    </Switch>
                </ScrollToTop>
            </div>
        </>
    );
}

export default App;
