import { combineReducers } from 'redux'

import userReducer from './features/user/userSlice'
import userDetailReducer from './features/user/userDetailSlice'
import userAboReducer from './features/user/userAboSlice'
import userConnectedReducer from './features/user/userConnectedSlice'
import userDeviceReducer from './features/user/userDeviceSlice'

import classeReducer from './features/classe/classeSlice'

import eleveReducer from './features/eleve/eleveSlice'
import eleveNbReducer from './features/eleve/eleveNbSlice'

import noteReducer from './features/note/noteSlice'
import eleveNbNoteReducer from './features/note/noteInfoSlice'

const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  userGlobal: userReducer,
  userDetail: userDetailReducer,
  userAbo: userAboReducer,
  userConnected: userConnectedReducer,
  userDevice: userDeviceReducer,
  classeDetail: classeReducer,
  noteDetail: noteReducer,
  eleveNbNoteDetail: eleveNbNoteReducer,
  eleveDetail: eleveReducer,
  eleveNb: eleveNbReducer,
})

const appReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    return rootReducer(undefined, action)
  }

  return rootReducer(state, action)
}

export default appReducer