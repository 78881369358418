const initialState = { abo: {id_type_abonnements: 0, date_debut: '', date_fin: '', date_paiement: '', cycle_1: 0, cycle_2: 0, cycle_3: 0, desactive: 0}}

export default function userAboReducer(state = initialState, action) {
  switch (action.type) {
    case 'user/userAbo': {
      // Can return just the new todos array - no extra object around it
      return {
        ...state,
        user: {
          id_type_abonnements: action.payload.id_type_abonnements,
          date_debut: action.payload.date_debut,
          date_fin: action.payload.date_fin,
          date_paiement: action.payload.date_paiement,
          cycle_1: action.payload.cycle_1,
          cycle_2: action.payload.cycle_2,
          cycle_3: action.payload.cycle_3,
          desactive: action.payload.desactive,
        }
      }
    }
    default:
      return state
  }
}