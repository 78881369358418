import React from 'react';

import { Card, CardHeader, CardBody, Image, Icon  } from '@material-tailwind/react';

import history from '../components/history';

import { useSelector, useDispatch } from 'react-redux'

import MyModalComponent from './MyModal';
import MyModalComponent2 from './MyModal2';
import MyModalCreateClasse from './ClasseModal/MyModalCreateClasse';
import MyModalDeleteClasse from './ClasseModal/MyModalDeleteClasse';
import MyModalModifClasse from './ClasseModal/MyModalModifClasse';
import MyModalModifDevice from './DeviceModal/MyModalModifDevice';
import MyModalDeleteDevice from './DeviceModal/MyModalDeleteDevice';

export default function CardTable() {

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalIsOpen2, setIsOpen2] = React.useState(false);
    const [modalIsOpen3, setIsOpen3] = React.useState(false);
    const [modalIsOpen4, setIsOpen4] = React.useState(false);
    const [modalIsOpen5, setIsOpen5] = React.useState(false);
    const [modalIsOpen6, setIsOpen6] = React.useState(false);
    const [modalIsOpen7, setIsOpen7] = React.useState(false);
    
    const [userSelect, setUserSelect] = React.useState('');
    const [classeSelect, setClasseSelect] = React.useState('');
    const [deviceNumber, setDeviceNumber] = React.useState();

    const user_connected = useSelector(state => state.userConnected.user_connected)
    const allClasse = useSelector(state => state.classeDetail.arr)
    const allDevice = useSelector(state => state.userDevice)
    const user = useSelector(state => state.userDetail.arr)

    const dispatch = useDispatch()

    function openFromParent(modalNumber, row, deviceNumberParams) {
        switch(modalNumber) {
          case 1:
            setIsOpen(true);
            setUserSelect(row);
            break;
          case 2:
            setIsOpen2(true);
            setUserSelect(row);
            break;
          case 3:
            setIsOpen3(true);
            break;
          case 4:
            setIsOpen4(true);
            setClasseSelect(row);
            break;
          case 5:
            setIsOpen5(true);
            setClasseSelect(row);
            break;
          case 6:
            setIsOpen6(true);
            setDeviceNumber(deviceNumberParams)
            break;
          case 7:
            setIsOpen7(true);
            setDeviceNumber(deviceNumberParams)
            break;
          default:
            break;
        }
    }

    function handleCloseModal(event, data) {
        switch(data) {
          case 1:
            setIsOpen(false);
            break;
          case 2:
            setIsOpen2(false);
            break;
          case 3:
            setIsOpen3(false);
            break;
          case 4:
            setIsOpen4(false);
            break;
          case 5:
            setIsOpen5(false);
            break;
          case 6:
            setIsOpen6(false);
            break;
          case 7:
            setIsOpen7(false);
            break;
          default:
            break;
        }
    }

    return (
        <div>
        <Card>
            <MyModalComponent
                IsModalOpened={modalIsOpen}
                onCloseModal={handleCloseModal}
                user={userSelect}
                key={Math.random()}
            />
            <MyModalComponent2
                IsModalOpened={modalIsOpen2}
                onCloseModal={handleCloseModal}
                user={userSelect}
                key={Math.random()}
            />
            <MyModalCreateClasse
                IsModalOpened={modalIsOpen3}
                onCloseModal={handleCloseModal}
                key={Math.random()}
            />
            <MyModalDeleteClasse
                IsModalOpened={modalIsOpen4}
                onCloseModal={handleCloseModal}
                classe={classeSelect}
                key={Math.random()}
            />
            <MyModalModifClasse
                IsModalOpened={modalIsOpen5}
                onCloseModal={handleCloseModal}
                classe={classeSelect}
                key={Math.random()}
            />
            <MyModalModifDevice
                IsModalOpened={modalIsOpen6}
                onCloseModal={handleCloseModal}
                deviceInfo={allDevice.userDevice}
                deviceNumberParams={deviceNumber}
                key={Math.random()}
            />
            <MyModalDeleteDevice
                IsModalOpened={modalIsOpen7}
                onCloseModal={handleCloseModal}
                deviceInfo={allDevice.userDevice}
                deviceNumberParams={deviceNumber}
                key={Math.random()}
            />
            <CardHeader color="blue" contentPosition="left">
                <h2 className="text-white text-2xl">Liste des utilisateurs</h2>
            </CardHeader>
            {user[0].id_user !== "" || user[0].pseudo !== "" ? (
                <CardBody>
                    <div className="overflow-x-auto">
                        <table className="items-center w-full bg-transparent border-collapse">
                            <thead>
                                <tr>
                                    <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                        Pseudo
                                    </th>
                                    <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                        Avatar
                                    </th>
                                    <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {user.map((row, index) => {
                                    return (
                                        <tr key={row.id_user} style={user_connected.connected === true ? ( user_connected.id_user === row.id_user ? {display: 'table-row'} : {display: 'none'} ) : {display: 'table-row'}}>
                                            <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                { row.pseudo }
                                            </th>
                                            <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                <div className="flex">
                                                    <div className="w-10 h-10 rounded-full border-2 border-white">
                                                        <Image
                                                            src={process.env.PUBLIC_URL + `/img/avatars/avatar_${row.id_avatar}.png`}
                                                            rounded
                                                            alt="avatar"
                                                        />
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                <div className="flex">
                                                    <div className="w-10 h-10 rounded-full border-2 border-white">
                                                        <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-4 rounded" style={user_connected.connected === true ? {display: 'inline'} : {display: 'none'}} onClick={(e) => {openFromParent(1, row)}}>Modifier</button>
                                                        <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded" style={user_connected.connected === true ? {display: 'none'} : {display: 'inline-block'}} onClick={(e) => {openFromParent(2, row)}}>Connexion</button>
                                                        <button className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded" style={user_connected.connected === true ? {display: 'inline'} : {display: 'none'}} onClick={(e) => {dispatch({ type: 'user/userDisconnect' })}}>Se déconnecter</button>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            ) : (
                <CardBody>
                    <div className="overflow-x-auto">
                        Aucun utilisateur
                    </div>
                </CardBody>
            )}
        </Card>
        <div style={user_connected.connected === true ? {display: 'block'} : {display: 'none'}}>
            <Card className="mt-12">
                <CardHeader color="blue" contentPosition="left">
                    <h2 className="text-white text-2xl">Mes classes</h2>
                </CardHeader>
                <CardBody>
                    <div className="overflow-x-auto">
                        {allClasse.length > 0 && allClasse[0].id_classe !== '' ?
                            <table className="items-center w-full bg-transparent border-collapse">
                                <thead>
                                    <tr>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Nom de la classe
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Nom de l'établissement
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Niveau(x)
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allClasse.map( row => {
                                        return (
                                            <tr key={row.id_classe}>
                                                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    { row.nom_classe }
                                                </th>
                                                 <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    { row.nom_etablissement }
                                                </th>
                                                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    { row.niveau }
                                                </th>
                                                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    <div>
                                                        <div className="ml-10 w-10 h-10 rounded-full border-2 border-white" style={{ display: 'grid', height: 'auto', width: 'fit-content' }}>
                                                            <div className="flex">
                                                                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-4 mb-1 rounded" style={{ width: '100%' }} onClick={(e) => {openFromParent(5, row)}} alt="Modifier"><Icon name="drive_file_rename_outline" size="xl" color="white" /></button>
                                                                <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 mb-1 rounded" style={{ width: '100%' }} onClick={(e) => {openFromParent(4, row)}} alt="Supprimer"><Icon name="delete" size="xl" color="white" /></button>
                                                            </div>
                                                            <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 mb-1  rounded" style={{ width: '100%' }} onClick={(e) => {history.push({pathname: '/classe', state: { detail: row }}); history.go('/classe')}}>Gestion des élèves</button>
                                                            <button className="bg-yellow-600 hover:bg-yellow-700 text-white font-bold py-2 px-4 mb-1 rounded" style={{ width: '100%' }} onClick={(e) => {history.push({pathname: '/suivi_eval', state: { detail: row }}); history.go('/suivi_eval')}}>Suivi évaluation</button>
                                                            <button className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded" style={{ width: '100%' }} onClick={(e) => {history.push({pathname: '/fil_annuel', state: { detail: row }}); history.go('/fil_annuel')}}>Fil annuel</button>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table> :
                            <span>Vous n'avez aucune classe</span>
                        }
                        <div className="flex mt-12">
                            <div className="rounded-full border-2 border-white">
                                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-4 rounded" onClick={(e) => {openFromParent(3, user_connected)}}>Créer une classe</button>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <Card className="mt-12">
                <CardHeader color="blue" contentPosition="left">
                    <h2 className="text-white text-2xl">Mes appareils</h2>
                </CardHeader>
                <CardBody>
                    <div className="overflow-x-auto">
                        {allDevice.userDevice.device_name_1 !== null || allDevice.userDevice.device_name_2 !== null ?
                            <table className="items-center w-full bg-transparent border-collapse">
                                <thead>
                                    <tr>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Nom appareil
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Adresse MAC
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allDevice.userDevice.device_name_1 !== null ?
                                        <tr key={allDevice.userDevice.device_name_1 + "1"}>
                                            <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                { allDevice.userDevice.device_name_1 }
                                            </th>
                                             <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                { allDevice.userDevice.mac_adress_1 }
                                            </th>
                                            <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                <div>
                                                    <div className="w-10 h-10 rounded-full border-2 border-white" style={{ display: 'grid', height: 'auto', width: 'fit-content' }}>
                                                        <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-4 mb-1 rounded" style={{ width: '100%' }} onClick={(e) => {openFromParent(6, allDevice.userDevice, 1)}}>Modifier</button>
                                                        <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 mb-1 rounded" style={{ width: '100%' }} onClick={(e) => {openFromParent(7, allDevice.userDevice, 1)}}>Supprimer</button>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr> : <tr></tr>
                                    }
                                    {allDevice.userDevice.device_name_2 !== null ?
                                        <tr key={allDevice.userDevice.device_name_2 + "2"}>
                                            <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                { allDevice.userDevice.device_name_2 }
                                            </th>
                                             <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                { allDevice.userDevice.mac_adress_2 }
                                            </th>
                                            <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                <div>
                                                    <div className="w-10 h-10 rounded-full border-2 border-white" style={{ display: 'grid', height: 'auto', width: 'fit-content' }}>
                                                        <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-4 mb-1 rounded" style={{ width: '100%' }} onClick={(e) => {openFromParent(6, allDevice.userDevice, 2)}}>Modifier</button>
                                                        <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 mb-1 rounded" style={{ width: '100%' }} onClick={(e) => {openFromParent(7, allDevice.userDevice, 2)}}>Supprimer</button>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr> : <tr></tr>
                                    }
                                </tbody>
                            </table> :
                            <span>Vous n'avez aucun appareil connecté</span>
                        }
                    </div>
                </CardBody>
            </Card>
        </div>
        </div>
    );
}
