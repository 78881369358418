import React, { useState } from 'react';

import { Document, Page, View, StyleSheet } from '@react-pdf/renderer';

import axios from 'axios';

import moment from 'moment'
import 'moment/locale/fr'

export default function CreatePdfClasse({idClasse, allSeance, startDate, endDate}) {
	const [getClasse, setClasse] = useState([{nom_classe: '', nbEleves: 0, niveau: ''}]);
	const pageHeightLimit = 800
	const rowHeight = 80

	var nbRow = allSeance.length
	var nbPage = Math.ceil((nbRow * rowHeight) / pageHeightLimit)

	var pdfHeight = (nbPage * pageHeightLimit) + "px"

	let nbPageArray = [];

	for (let i = 1; i <= nbPage; i++) {
	    nbPageArray.push([i]);
	}

	function splitArrayIntoChunks(array, chunkSize) {
	  let result = [];
	  for (let i = 0; i < array.length; i += chunkSize) {
	    result.push(array.slice(i, i + chunkSize));
	  }
	  return result;
	}

	const chunkedArray = splitArrayIntoChunks(allSeance, 9);

    React.useEffect(() => {
    	setClasse([{nom_classe: '', nbEleves: 0, niveau: ''}])

    	axios.get('https://eps.acces-editions.com/api/getMyClasseId?id_classe='+idClasse)
	    .then((res) => {
	        if(res.data[0] !== undefined)
	        {
	            setClasse(res.data)
	        }
	    })
        window.scrollTo(0, 0)
    }, [idClasse]);

	const styles = StyleSheet.create({
	  display: {
	  	display: 'none',
	  	height: pdfHeight
	  },
	  contentHeight: {
	  	height: '840px',
	  	display: 'block'
	  },
	  page: {
	  	width: '492px',
	  },
	  header: {
	  	height: '110px'
	  },
	  card: {
	    display: 'inline-flex',
	    justifyContent: 'center',
	    fontSize: '10px',
	    fontWeight: 'bold',
	    padding: '5px',
	    color: 'black',
	    wordSpacing: '4px',
	    alignItems: 'center',
	    paddingLeft: '10px',
    	paddingRight: '10px'
	  },
	  card2: {
	  	backgroundColor: '#03A9F4',
	  	color: 'white',
	    textAlign: 'center',
	    fontSize: '10px',
	    paddingBottom: '10px',
	    width: '-webkit-fill-available',
	    justifyContent: 'center',
	    alignItems: 'center',
	    borderRight: '1px solid white',
    	paddingLeft: '5px',
    	paddingRight: '5px'
	  },
	  card2Last: {
	  	backgroundColor: '#03A9F4',
	  	color: 'white',
	    textAlign: 'center',
	    fontSize: '10px',
	    paddingBottom: '10px',
	    width: '-webkit-fill-available',
	    justifyContent: 'center',
	    alignItems: 'center',
    	paddingLeft: '5px',
    	paddingRight: '5px'
	  },
	  card3: {
	  	display: 'inline-flex',
	    justifyContent: 'center',
	    fontSize: '12px',
	    fontWeight: 'bold',
	    padding: '5px',
	    color: 'black',
	    wordSpacing: '4px',
	    alignItems: 'center'
	  },
	  headerText: {
	  	fontSize: '12px',
	  	display: 'inline-flex',
	    justifyContent: 'center',
	    fontWeight: 'bold',
	    padding: '10px',
	    color: 'black',
	    wordSpacing: '4px',
	    alignItems: 'center'
	  },
	  headerText2: {
	  	fontSize: '12px',
	  	display: 'inline-block',
	    fontWeight: 'bold',
	    padding: '10px',
	    color: 'black',
	    wordSpacing: '4px'
	  },
	  eval: {
	  	fontSize: '10px',
	  	textAlign: 'center'
	  },
	  centerImage: {
	  	textAlign: 'center',
	    display: 'block',
	    marginLeft: 'auto',
	    marginRight: 'auto'
	  },
	  border: {
	  	border: '2px solid #03A9F4'
	  },
	  block: {
	  	display: 'inline-block'
	  },
	  border2: {
	  	borderWidth: '0px 2px 2px 2px',
	  	borderColor: '#03A9F4'
	  },
	  border3: {
	  	borderWidth: '2px 2px 2px 0px',
	  	borderColor: '#03A9F4'
	  },
	  whiteRow: {
	  	backgroundColor: 'white'
	  },
	  greyRow: {
	  	backgroundColor: '#D5D5D5'
	  },
	  rowCol: {
	  	minHeight: '25px',
	  	maxHeight: '25px'
	  },
	  edito: {
	  	fontSize: '9px',
	  	color: 'grey'
	  },
	  textUnderline: {
	  	fontWeight: '300',
	  	fontSize: '20px',
	  	marginBottom: '20px'
	  },
	  textDescription: {
	  	fontSize: '12px'
	  },
	  borderBlack: {
	  	borderRight: '2px solid black'
	  },
	  textExplication: {
	  	color: 'grey',
	  	fontSize: '10px',
	  	marginTop: '50px',
	  	marginBottom: '20px'
	  },
	  spanDisplay: {
	  	fontSize: '10px'
	  }
	});

	return(
		<Document style={styles.display}>
			<div id={"fil_annuel"} style={styles.page}>
			{nbPageArray.map((pageRow, pageIndex) => {
				return(
					<div style={styles.contentHeight}>
						<Page key={"export_fil_annuel_" + pageIndex} size="A4" style={styles.contentHeight}>
							<div>
								<div className="col-span-1 col-span-2 col-span-3 col-span-4 col-span-5 col-span-6 col-span-7 col-span-8 col-span-9 col-span-10 col-span-11 col-span-12" ></div>	
									<div>
										<div className="pt-3 px-3 md:px-3" style={styles.header}>
							                <div className="container mx-auto max-w-full">
							                	<div className="grid grid-rows-1 grid-flow-col">
							                    	<div className="col-span-3" style={styles.headerText2}>
							                    		<div style={styles.textUnderline}>{'Fil annuel EPS'} </div>
							                    		<div style={styles.textDescription}>{'Classe : ' + getClasse[0].nom_classe}</div>
							                    		<div style={styles.textDescription}>{'Niveau : ' + getClasse[0].niveau}</div>
							                    		<div style={styles.textDescription}>{'Période : ' + moment(startDate).format('DD MMMM YYYY') + ' au ' + moment(endDate).format('DD MMMM YYYY')}</div>
							                    	</div>
							                   	</div>
							                   	<div className="grid grid-rows-1 grid-cols-12 lg:grid-cols-12 xl:grid-cols-12">
							                   		<div className="col-span-12 mb-10" style={styles.headerText2}><span style={styles.textExplication}>Synthèse des disciplines abordées pour lesquelles il y a eu au moins une évaluation d'activité.</span></div>
							                   		<div className="col-span-6" style={styles.headerText2}>
							                   		{chunkedArray[pageIndex].length > 0 ?
							                   			pageRow.map((viewRow, viewIndex) => {
							                   				return(
									                   			<View style={styles.tableRow} key={"view_"+viewIndex}>
												                    <table className="items-center w-full border-collapse border border-slate-400">
												                        <thead>
												                            <tr>
												                                <th className="px-2 bg-purple-100 text-purple-500 align-middle border border-solid border-gray-200 pt-2 pb-6 text-sm whitespace-nowrap font-light">
												                                    <span className="flex justify-center items-center font-medium">Champ d'apprentissage</span>
												                                </th>
												                                <th className="px-2 bg-purple-100 text-purple-500 align-middle border border-solid border-gray-200 pt-2 pb-6 text-sm whitespace-nowrap font-light">
												                                    <span className="flex justify-center items-center font-medium">Discipline</span>
												                                </th>
												                            </tr>
												                        </thead>
												                        <tbody>
												                             {chunkedArray[pageIndex].map((row, index) => {
												                                return (
													                            	<tr key={row.id_cycle + Math.random()}>
													                            	    <th className="border border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 pt-2 pb-6">
													                            	        <span className="flex justify-center items-center font-normal">{row.nom_competence}</span>
													                            	    </th>
													                            	    <th className="border border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 pt-2 pb-6">
													                            	        <span className="flex justify-center items-center font-normal">{row.nom_sequence}</span>
													                            	    </th>
													                            	</tr>
												                                )
												                            })}
												                        </tbody>
												                    </table>
												                    <span style={styles.spanDisplay}>{"Page : " + (pageIndex+1) + "/" + nbPage}</span>
												                </View>
											            )})
								                        :
								                        <div>
								                        </div>
							                        }
							                    </div>
							                </div>
							            </div>
							        </div>
							    </div>
							</div>
						</Page>
					</div>
				)
			})}
			</div>
		</Document>
	)
}