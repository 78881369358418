import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from '@material-tailwind/react';
import StatusCard from 'components/StatusCard';

import EnterSend from "../EnterSend";

import { Rating } from 'react-simple-star-rating'

import { useDispatch } from 'react-redux'

import { NotificationManager } from 'react-notifications';

import axios from 'axios';

function MyModalModifNote({IsModalOpened, onCloseModal, infoNote}) {

  const dispatch = useDispatch()

  const [changeNote, setChangeNote] = React.useState(0)

  const handleRating = (rate: number, index: number) => {
    var note = index + 1
    setChangeNote(note)
  }

  const handleReset = () => {
    setChangeNote(0)
  }

  const handleSubmit = () => {
    if(IsModalOpened === true) {
      saveModif()
    }
  };

  function onModalClose(event) {
    onCloseModal(event, 1);
  }

  React.useEffect(() => {
    setChangeNote(infoNote.note)
  }, [infoNote.note]);

  function saveModif() {
    if(changeNote === undefined) {
      NotificationManager.error('La notation est invalide', '', 3000);
      return
    }
    const formData = new FormData();

    formData.append('id_classe', infoNote.id_classe)
    formData.append('id_seance', infoNote.id_seance)
    formData.append('id_eleve', infoNote.id_eleve)
    formData.append('id_cycle', infoNote.id_cycle)
    formData.append('id_sequence', infoNote.id_sequence)
    formData.append('note', changeNote)

    axios.post('https://eps.acces-editions.com/api/createNote', formData)
    .then((res) => {
      axios.get('https://eps.acces-editions.com/api/getNoteEleveSceance?id_classe='+infoNote.id_classe+'&id_seance='+infoNote.id_seance)
      .then((res) => {
        if(res.data[0] !== undefined && res.data[0].id_eleve)
        {
          NotificationManager.success('Évaluation modifié', '', 3000);
          dispatch({ type: 'note/noteDetail', payload: res.data});
          axios.get('https://eps.acces-editions.com/api/getNoteClasseSeanceSpec?id_classe='+infoNote.id_classe+'&id_seance='+infoNote.id_seance)
          .then((res) => {
              if(res.data[0] !== undefined)
              {
                  dispatch({ type: 'note/eleveNbNoteDetail', payload: res.data[0].nbNote});
              }
          })
          onModalClose(false);
        }
      })
    })
  }

  return (
    <>
      <Modal size="sm" active={IsModalOpened} toggler={() => {return}}>
        <ModalHeader toggler={() => onModalClose(false)}>
            Modifier l'évaluation
        </ModalHeader>
        <ModalBody>
          <div className="mt-10 mb-12 flex flex-row">
            <StatusCard
                color="pink"
                icon="account_circle"
                title="Prénom de l'élève"
                amount={infoNote.prenom !== undefined ? infoNote.prenom : ''}
            />
            <StatusCard
                color="blue"
                icon="account_circle"
                title="Nom de l'élève"
                amount={infoNote.nom !== undefined ? infoNote.nom : ''}
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ display: 'inline' }}>
              <Rating key={Math.random()} 
                onClick={handleRating}
                initialValue={changeNote}
                size={40}
                label
                transition
                fillColor='orange'
                emptyColor='gray'
                iconsCount={4}
                className='foo'/>
            </div>
            <Button
              style={{ margin: '0 auto', marginTop: '20px' }}
              color="green"
              buttonType="outline"
              onClick={(e) => handleReset()}
            >
              Non évalué
            </Button>
          </div>
        </ModalBody>
        <ModalFooter>
            <Button 
                color="red"
                buttonType="link"
                onClick={(e) => onModalClose(false)}
                ripple="dark"
            >
              Fermer
            </Button>
            <Button
                color="green"
                onClick={(e) => saveModif()}
                ripple="light"
            >
              <EnterSend handleSubmit={handleSubmit}/>
              Modifier
            </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default MyModalModifNote;