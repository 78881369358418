import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from '@material-tailwind/react';

import EnterSend from "../EnterSend";

import { NotificationManager } from 'react-notifications';

import axios from 'axios';

import moment from 'moment'
import 'moment/locale/fr'

function MyModalModifAbo({IsModalOpened, onCloseModal, allInfo, getInfoUser, indexSelected}) {

  const [idAbo, setIdAbo] = React.useState('');
  const [idCompte, setIdCompte] = React.useState('');
  const [datePay, setDatePay] = React.useState('');
  const [dateStart, setDateStart] = React.useState('');
  const [dateEnd, setDateEnd] = React.useState('');
  const [typeAbo, setTypeAbo] = React.useState('');
  const [cycle1, setCycle1] = React.useState(false);
  const [cycle2, setCycle2] = React.useState(false);
  const [cycle3, setCycle3] = React.useState(false);

  const handleSubmit = () => {
    if(IsModalOpened === true) {
      modifAbo()
    }
  }

  function onModalClose(event) {
    onCloseModal(event, 2);
  }

  React.useEffect(() => {
    if(indexSelected !== undefined && indexSelected !== null && allInfo.length > 0) {
      if(allInfo[indexSelected] !== undefined) {
        if(allInfo[indexSelected].no_abo !== true) {
          setIdAbo(allInfo[indexSelected].id_abonnement)
          setIdCompte(allInfo[indexSelected].id_compte)
          setDatePay(moment(allInfo[indexSelected].date_paiement).format("YYYY-MM-DD"))
          setDateStart(moment(allInfo[indexSelected].date_debut).format("YYYY-MM-DD"))
          setDateEnd(moment(allInfo[indexSelected].date_fin).format("YYYY-MM-DD"))
          setTypeAbo(allInfo[indexSelected].id_type_abonnements)
          setCycle1(allInfo[indexSelected].cycle_1)
          setCycle2(allInfo[indexSelected].cycle_2)
          setCycle3(allInfo[indexSelected].cycle_3)
        }
      }
    }
  }, [allInfo, indexSelected]);

  function modifAbo() {
    if(idAbo !== '' && idCompte !== '' && datePay !== '' && dateStart !== '' && dateEnd !== '' && typeAbo !== '' && (cycle1 !== false || cycle2 !== false || cycle3 !== false)) {
      const formData = new FormData();
      formData.append('id_abonnement', idAbo)
      formData.append('id_compte', idCompte)
      formData.append('date_pay', moment(datePay).format('YYYY-MM-DD HH:mm:ss'))
      formData.append('date_start', moment(dateStart).format('YYYY-MM-DD HH:mm:ss'))
      formData.append('date_end', moment(dateEnd).format('YYYY-MM-DD HH:mm:ss'))
      formData.append('id_type_abonnements', typeAbo)
      formData.append('cycle1', cycle1 === true || cycle1 === 1 ? 1 : 0)
      formData.append('cycle2', cycle2 === true || cycle2 === 1 ? 1 : 0)
      formData.append('cycle3', cycle3 === true || cycle3 === 1 ? 1 : 0)

      axios.post('https://eps.acces-editions.com/api/updateAbonnementWithId', formData)
      .then((res) => {
        if(res.status === 200)
        {
          NotificationManager.success('Abonnement modifié', '', 3000);
          onModalClose(true)
          getInfoUser(idCompte)
        } else {
          NotificationManager.error('Une erreur s\'est produite', '', 3000);
        }
      })
    } else {
      NotificationManager.error('Erreur saisie dans les champs', '', 3000);
    }
  }

  return (
    <>
      <Modal size="md" active={IsModalOpened} toggler={() => {return}}>
        <ModalHeader toggler={() => onModalClose(false)}>
            Modifier abonnement
        </ModalHeader>
        <ModalBody>
          <div style={{ textAlign: 'center' }}>
            <div className="mb-6 grid grid-cols-2 gap-4">
              <label className="col-span-2 block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                Modification abonnement
              </label>
              <input className="col-span-2 border border-inherit p-2 rounded" value={idCompte} type="number" placeholder="Id compte" onChange={(e) => setIdCompte(e.target.value) }/>
              <input className="col-span-2 border border-inherit p-2 rounded" value={datePay} type="date" placeholder="Date paiement abo" onChange={(e) => setDatePay(e.target.value) }/>
              <input className="col-span-2 border border-inherit p-2 rounded" value={dateStart} type="date" placeholder="Date début abo" onChange={(e) => setDateStart(e.target.value) }/>
              <input className="col-span-2 border border-inherit p-2 rounded" value={dateEnd} type="date" placeholder="Date fin abo" onChange={(e) => setDateEnd(e.target.value) }/>
              <select className="col-span-2 border border-inherit p-2 rounded" value={typeAbo} onChange={(e) => setTypeAbo(e.target.value) }>
                <option value="0">Choix nb cycle / nb user</option>
                <option value="1">1 cycle / 1 user</option>
                <option value="2">1 cycle / 2 user</option>
                <option value="3">1 cycle / 3 user</option>
                <option value="4">1 cycle / 4 user</option>
                <option value="5">1 cycle / 5 user</option>
                <option value="6">1 cycle / 6 user</option>
                <option value="7">1 cycle / 8 user</option>
                <option value="8">1 cycle / 10 user</option>
                <option value="16">2 cycle / 1 user</option>
                <option value="17">2 cycle / 2 user</option>
                <option value="18">2 cycle / 3 user</option>
                <option value="19">2 cycle / 4 user</option>
                <option value="20">2 cycle / 5 user</option>
                <option value="21">2 cycle / 6 user</option>
                <option value="22">2 cycle / 8 user</option>
                <option value="23">2 cycle / 10 user</option>
                <option value="24">3 cycle / 1 user</option>
                <option value="25">3 cycle / 2 user</option>
                <option value="26">3 cycle / 3 user</option>
                <option value="27">3 cycle / 4 user</option>
                <option value="28">3 cycle / 5 user</option>
                <option value="29">3 cycle / 6 user</option>
                <option value="30">3 cycle / 8 user</option>
                <option value="31">3 cycle / 10 user</option>
              </select>
              <div className="flex col-span-2"><input type="checkbox" value={cycle1} checked={cycle1} onChange={(e) => setCycle1(e.target.checked) }/><span className="pl-2">Cycle 1</span></div>
              <div className="flex col-span-2"><input type="checkbox" value={cycle2} checked={cycle2} onChange={(e) => setCycle2(e.target.checked) }/><span className="pl-2">Cycle 2</span></div>
              <div className="flex col-span-2"><input type="checkbox" value={cycle3} checked={cycle3} onChange={(e) => setCycle3(e.target.checked) }/><span className="pl-2">Cycle 3</span></div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
            <Button 
                color="red"
                buttonType="link"
                onClick={(e) => onModalClose(false)}
                ripple="dark"
            >
              Fermer
            </Button>
            <Button
                color="green"
                onClick={(e) => modifAbo()}
                ripple="light"
            >
              <EnterSend handleSubmit={handleSubmit}/>
              Valider
            </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default MyModalModifAbo;