import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from '@material-tailwind/react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { useSelector, useDispatch } from 'react-redux'

import EnterSend from "../EnterSend";

import { NotificationManager } from 'react-notifications';

import axios from 'axios';

function MyModalModifClasse({IsModalOpened, onCloseModal, classe}) {

  function onModalClose(event) {
    onCloseModal(event, 5);
  }

  const user = useSelector(state => state.userGlobal.user)
  const user_connected = useSelector(state => state.userConnected.user_connected)
  const dispatch = useDispatch()

  const animatedComponents = makeAnimated();

  const [nomClasse, setNomClasse] = React.useState();
  const [nomEtablissement, setNomEtablissement] = React.useState();
  const [niveauClasse, setNiveauClasse] = React.useState(classe.niveau !== undefined ? classe.niveau.split(', ').map((c) => {return { value: c, label: c }}) : []);

  const handleSubmit = () => {
    if(IsModalOpened === true) {
      saveModif()
    }
  };

  React.useEffect(() => {
    setNomClasse(classe.nom_classe)
    setNomEtablissement(classe.nom_etablissement)
    setNiveauClasse(classe.niveau !== undefined ? classe.niveau.split(', ').map((c) => {return { value: c, label: c }}) : [])
  }, [classe.nom_classe, classe.nom_etablissement, classe.niveau]);

  function saveModif() {
    if(niveauClasse === undefined || nomClasse === undefined ||  niveauClasse === '' || nomClasse === '' || niveauClasse.length <= 0) {
      NotificationManager.error('Vous devez remplir tout les champs', '', 3000);
      return
    }

    const formData = new FormData();
    var niveau = niveauClasse.map((c, index) => c.value ).join(', ')

    formData.append('id_compte', user.id_compte)
    formData.append('id_user', user_connected.id_user)
    formData.append('id_classe', classe.id_classe)
    formData.append('nom_classe', nomClasse)
    formData.append('nom_etablissement', nomEtablissement)
    formData.append('niveau', niveau)
    
    axios.post('https://eps.acces-editions.com/api/updateMyClasse', formData)
    .then((res) => {
      axios.get('https://eps.acces-editions.com/api/getMyClasse?id_compte='+user.id_compte+'&id_user='+user_connected.id_user)
      .then((res) => {
        if(res.data[0] !== undefined && res.data[0].id_classe)
        {
          NotificationManager.success('Classe modifiée', '', 3000);
          dispatch({ type: 'user/classeDetail', payload: res.data});
          onModalClose(false);
        }
      })
    })
  }

  const colourOptions = [
    { value: 'TPS', label: 'TPS' },
    { value: 'PS', label: 'PS' },
    { value: 'MS', label: 'MS' },
    { value: 'GS', label: 'GS' },
    { value: 'CP', label: 'CP' },
    { value: 'CE1', label: 'CE1' },
    { value: 'CE2', label: 'CE2' },
    { value: 'CM1', label: 'CM1' },
    { value: 'CM2', label: 'CM2' },
    { value: '6e', label: '6e' },
  ];

  return (
    <>
      <Modal size="sm" active={IsModalOpened} toggler={() => {return}}>
        <ModalHeader toggler={() => onModalClose(false)}>
            Modifier la classe
        </ModalHeader>
        <ModalBody>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Nom de la classe
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type='text' defaultValue={nomClasse} onInput={(e) => { setNomClasse(e.target.value) }} />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Nom de l'établissement
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type='text' defaultValue={nomEtablissement} onInput={(e) => { setNomEtablissement(e.target.value)}} />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Niveau(x)
            </label>
            <Select
              closeMenuOnSelect={false}
              value={niveauClasse}
              components={animatedComponents}
              isMulti
              name="properties"
              className="basic-multi-select"
              classNamePrefix="select"
              options={colourOptions}
              onChange={(e) => {setNiveauClasse(e)}}
            />
          </div>
        </ModalBody>
        <ModalFooter>
            <Button 
                color="red"
                buttonType="link"
                onClick={(e) => onModalClose(false)}
                ripple="dark"
            >
                Fermer
            </Button>

            <Button
                color="green"
                onClick={(e) => saveModif()}
                ripple="light"
            >
              <EnterSend handleSubmit={handleSubmit}/>
              Modifier
            </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default MyModalModifClasse;