import React from 'react';
import axios from 'axios';
import history from '../components/history';
import BackButton from '../components/BackButton.js';
import Logo from 'assets/img/Logo_EPS_300.png';

import { NotificationManager } from 'react-notifications';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-regular-svg-icons'
import { faEyeSlash } from '@fortawesome/free-regular-svg-icons'

const SendEmailForm = ({SendEmailSubmit, EditPasswordSubmit, mailSend, setMailSend, emailSave, setEmail}) => {
  let email;
  let password;
  let conf_password;
  let code_unique;

  const [showPasswordValue, setShowPasswordValue] = React.useState(false);
  const [showPasswordValue2, setShowPasswordValue2] = React.useState(false);

  function showPassword() {
    setShowPasswordValue(!showPasswordValue)
  }

  function showPassword2() {
    setShowPasswordValue2(!showPasswordValue2)
  }

  return (
    <div className="App">
      <header className="header">
        <BackButton />
        <div className="flex flex-wrap justify-center">
          <img
          src={Logo}
          className="mt-10 mb-10"
          alt="..."
        />
        </div>
        {mailSend === true ?
          <div className="grid justify-items-center mb-6">
            <p className="block text-blue-900 font-bold md:text-right mb-1 md:mb-0 pr-4">Saisissez un nouveau mot de passe</p>
            <p className="block text-blue-900 font-bold md:text-right mb-1 md:mb-0 pr-4">Entrez le code unique pour réinitialiser votre mot de passe</p>
          </div>
          :
          <div className="grid justify-items-center mb-6">
            <p className="block text-blue-900 font-bold md:text-right mb-1 md:mb-0 pr-4">Mot de passe oublié ?</p>
            <p className="block text-blue-900 font-bold md:text-right mb-1 md:mb-0 pr-4">Un code unique vous serva envoyé à l'adresse e-mail suivante :</p>
          </div>
        }
      </header>

      <form className="container mx-auto">
        {mailSend === true ?
          <div>
            <div className="grid justify-items-center mb-6">
              <div className="md:w-2/3" style={{ display: 'flex' }}>
                <input className="bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-grey-500" id="inline-full-name" type={showPasswordValue === false ? 'password' : 'text'} placeholder="Mot de passe" ref={mdpValue => {
                  password = mdpValue;
                }} />
                <button type="button" onClick={(e) => { e.preventDefault(); showPassword() }} className="bg-gray-200 appearance-none border-2 border-gray-200 py-2 px-4 text-gray-700 leading-tight focus:outline-none">{showPasswordValue === false ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} /> }</button>
              </div>
            </div>
             <div className="grid justify-items-center mb-6">
              <div className="md:w-2/3" style={{ display: 'flex' }}>
                <input className="bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-grey-500" id="inline-full-name" type={showPasswordValue2 === false ? 'password' : 'text'} placeholder="Confirmation mot de passe" ref={confMdpValue => {
                  conf_password = confMdpValue;
                }} />
                <button type="button" onClick={(e) => { e.preventDefault(); showPassword2() }} className="bg-gray-200 appearance-none border-2 border-gray-200 py-2 px-4 text-gray-700 leading-tight focus:outline-none">{showPasswordValue2 === false ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} /> }</button>
              </div>
            </div>
            <div className="grid justify-items-center mb-6">
              <div className="md:w-2/3">
                <input className="bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-grey-500" id="inline-full-name" type="text" placeholder="Code unique" ref={codeUniqueValue => {
                  code_unique = codeUniqueValue;
                }} />
              </div>
            </div>
            <div className="grid justify-items-center mb-6">
              <div className="">
                <button className="shadow bg-green-500 hover:bg-green-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  EditPasswordSubmit(emailSave, password.value, conf_password.value, code_unique.value);
                }}>
                  Confirmer
                </button>
              </div>
            </div>
          </div>
          :
          <div>
            <div className="grid justify-items-center mb-6">
              <div className="md:w-2/3">
                <input className="bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-grey-500" id="inline-full-name" type="email" placeholder="Email" ref={loginValue => {
                  email = loginValue;
                }} />
              </div>
            </div>
            <div className="grid justify-items-center mb-6">
              <div className="">
                <button className="shadow bg-green-500 hover:bg-green-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  SendEmailSubmit(email.value, setMailSend, setEmail);
                  email.value = '';
                }}>
                  Confirmer
                </button>
              </div>
            </div>
          </div>
        }
      </form>
    </div>
  );
};

const SendEmailSubmit = (email, setMailSend, setEmail) => {
  const formData = new FormData();

  formData.append('email', email)

  if(!/.+@.+\.[A-Za-z]+$/.test(email)) {
    NotificationManager.error("Cet e-mail est invalide", '', 3000);
    return
  }

  axios.post('https://eps.acces-editions.com/api/forgotPasswordEmail', formData)
  .then((res) => {
    if(res.data.results !== undefined && res.data.results.affectedRows === 1)
    {
      NotificationManager.success("Un e-mail a été envoyé", '', 3000);
      setMailSend(true)
      setEmail(email)
    } else {
      NotificationManager.error("Une erreur c'est produit ou cet e-mail n'existe pas", '', 3000);
    }
  });
}

const EditPasswordSubmit = (email, password, conf_password, code_unique) => {
  const formData = new FormData();

  formData.append('email', email)
  formData.append('password', password)
  formData.append('code_unique', code_unique)

  if(/[A-Z]/.test(password) === false || /[a-z]/.test(password) === false || /[0-9]/.test(password) === false || /[^A-Za-z0-9]/.test(password) === false || (password.length > 7) === false) {
    NotificationManager.error("Le mot de passe est invalide", '', 3000);
    return
  }

  if(password !== conf_password) {
    NotificationManager.error("Le mot de passe et la confirmation sont différent", '', 3000);
    return
  }

  axios.post('https://eps.acces-editions.com/api/updatePasswordClient', formData)
  .then((res) => {
    if(res.data.results !== undefined && res.data.results.affectedRows === 1)
    {
      NotificationManager.success("Votre mot de passe à été mis à jour", '', 3000);
      history.goBack()
    } else {
      NotificationManager.error("Une erreur c'est produit ou le code unique est incorrect", '', 3000);
    }
  });
}

const ForgotPassword = () => {
  const [mailSend, setMailSend] = React.useState(false);
  const [emailSave, setEmail] = React.useState('');

  return (
    <SendEmailForm SendEmailSubmit={SendEmailSubmit} EditPasswordSubmit={EditPasswordSubmit} mailSend={mailSend} setMailSend={setMailSend} emailSave={emailSave} setEmail={setEmail}/>
  );
}

export default ForgotPassword;