import React, { useState } from 'react';

import { Card, CardHeader, CardBody } from '@material-tailwind/react';
import CreatePdfClasse from 'components/CreatePdfClasse';
import JsPDF from 'jspdf';

import axios from 'axios';

import { useSelector } from 'react-redux'

export default function ClasseNoteCardEval({idClasse, positionEleve, suivi}) {
    const [cycleSelected, setCycleSelected] = useState(0);
    const [competenceSelected, setCompetenceSelected] = useState({'id_competence': 0});
    const [sequenceSelected, setSequenceSelected] = useState({'id_sequence': 0});
    const [categorieSelected, setCategorieSelected] = useState({'id_categorie': 0});

    const [listeCompetence, setListeCompetence] = useState([]);
    const [listeSequence, setListeSequence] = useState([]);
    const [listeCategorie, setListeCategorie] = useState([]);
    const [allNoteSeance, setAllNoteSeance] = useState([]);

    const user_abo = useSelector(state => state.userAbo.user)

    function generatePDF(i) {
        const report = new JsPDF('l','pt','a4');

        report.html(document.querySelector('#export_activity_'+i)).then(() => {
            report.save('report.pdf');
        });
    }

    const ButtonExport = ({allNoteSeance}) => {
        var nbPage = 1
        if(allNoteSeance.length > 6) {
            nbPage = Math.round(allNoteSeance.length / 6)
        }

        var content = []

        for (var i = 1; i <= nbPage; i++) {
            content.push(<button key={"btnExport_" + i} id={"btnExport_" + i} onClick={(e) => {e.preventDefault(); generatePDF(e.target.value)}} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded" style={{ width: '100%' }} type="button" value={i}>{"Export PDF " + i + "/" + nbPage}</button>)
        }

        return(content)
    }

    const selectCycle = (value) => {
        setCycleSelected(value)
        setCompetenceSelected({'id_competence': 0})
        setSequenceSelected({'id_sequence': 0})
        setCategorieSelected({'id_categorie': 0})
        axios.get('https://eps.acces-editions.com/api/getCompetence?id_cycle='+value)
        .then((res) => {
            if(res.data.listeCompetence[0] !== undefined)
            {
                setListeCompetence(res.data.listeCompetence)
            } else {
                setListeCompetence([])
            }
        })
    }

    const selectCompetence = (value) => {
        value = JSON.parse(value)
        setCompetenceSelected(value)
        setSequenceSelected({'id_sequence': 0})
        setCategorieSelected({'id_categorie': 0})
        axios.get('https://eps.acces-editions.com/api/getSequence?id_competence='+value.id_competence)
        .then((res) => {
            if(res.data.listeSequence[0] !== undefined)
            {
                setListeSequence(res.data.listeSequence)
            } else {
                setListeSequence([])
            }
        })
    }

    const selectSequence = (value) => {
        value = JSON.parse(value)
        setSequenceSelected(value)
        setCategorieSelected({'id_categorie': 0})
        setAllNoteSeance([])
        axios.get('https://eps.acces-editions.com/api/getCategorie?id_sequence='+value.id_sequence)
        .then((res) => {
            if(res.data.listeCategorie[0] !== undefined)
            {
                setListeCategorie(res.data.listeCategorie)
            } else {
                setListeCategorie([])
                axios.get('https://eps.acces-editions.com/api/getSeance?id_categorie="-1"&id_sequence='+value.id_sequence)
                .then((res) => {
                    res.data.allSeance.map((row, index) => {
                        return axios.get('https://eps.acces-editions.com/api/getNoteClasseSeanceSpec?id_classe='+idClasse+'&id_seance='+row.id)
                        .then((res) => {
                            if(res.data[0] !== undefined)
                            {
                                axios.get('https://eps.acces-editions.com/api/getDetailSeance?id='+res.data[0].id_seance)
                                .then((result) => {
                                    setAllNoteSeance(prev => {return [...prev, result.data.detailSeance[0]]})
                                })
                            }
                        })
                    })
                })
            }
        })
    }

    const selectCategorie = (value) => {
        value = JSON.parse(value)
        setCategorieSelected(value)
        setAllNoteSeance([])
        axios.get('https://eps.acces-editions.com/api/getSeance?id_categorie='+value.id_categorie+'&id_sequence='+sequenceSelected.id_sequence)
        .then((res) => {
            res.data.allSeance.map((row, index) => {
                return axios.get('https://eps.acces-editions.com/api/getNoteClasseSeanceSpec?id_classe='+idClasse+'&id_seance='+row.id)
                .then((res) => {
                    if(res.data[0] !== undefined)
                    {
                        axios.get('https://eps.acces-editions.com/api/getDetailSeance?id='+res.data[0].id_seance)
                        .then((result) => {
                            setAllNoteSeance(prev => {return [...prev, result.data.detailSeance[0]]})
                        })
                    }
                })
            })
        })
    }

    return (
        <div>
            <Card className="mt-12">
                <CardHeader color="yellow" contentPosition="left">
                    <h2 className="text-white text-2xl">Export suivi évaluation</h2>
                </CardHeader>
                <CardBody>
                    <div>
                        <select onChange={(e) => selectCycle(e.target.value)} class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="0">Choix du cycle</option>
                            <option value="3" style={user_abo['cycle_3'] === 1 ? {display: "block"} : {display: "none"}}>Cycle 3</option>
                            <option value="2" style={user_abo['cycle_2'] === 1 ? {display: "block"} : {display: "none"}}>Cycle 2</option>
                            <option value="1" style={user_abo['cycle_1'] === 1 ? {display: "block"} : {display: "none"}}>Cycle 1</option>
                        </select>
                        {cycleSelected !== 0 ?
                            <select onChange={(e) => selectCompetence(e.target.value)} class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="0">Choix compétence</option>
                                {
                                    listeCompetence.map((row, index) => {
                                        return( <option key={row.id_competence} value={JSON.stringify(row)}>{row.nom}</option>)
                                    })
                                }
                            </select>
                            :
                            <select style={{ display: 'none'}}></select>
                        }
                        {competenceSelected.id_competence !== 0 ?
                            <select onChange={(e) => selectSequence(e.target.value)} class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="0">Choix séquence</option>
                                {
                                    listeSequence.map((row, index) => {
                                        return( <option key={row.id_sequence} value={JSON.stringify(row)}>{row.nom}</option>)
                                    })
                                }
                            </select>
                            :
                            <select style={{ display: 'none'}}></select>
                        }
                        {sequenceSelected.id_sequence !== 0 && listeCategorie.length > 0 ?
                            <select onChange={(e) => selectCategorie(e.target.value)} class="mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="0">Choix catégorie</option>
                                {
                                    listeCategorie.map((row, index) => {
                                        return( <option key={row.id_categorie} value={JSON.stringify(row)}>{row.nom}</option>)
                                    })
                                }
                                <option value={JSON.stringify({'id_categorie': 'all', nom: "Toutes les catégories", url_img: 'nat1'})}>Toutes</option>
                            </select>
                            :
                            <select style={{ display: 'none'}}></select>
                        }
                        {allNoteSeance.length > 0 ?
                            <div>
                                <CreatePdfClasse idClasse={idClasse} allNoteSeance={allNoteSeance} cycleSelected={cycleSelected} competenceSelected={competenceSelected} sequenceSelected={sequenceSelected} categorieSelected={categorieSelected} />
                                <ButtonExport allNoteSeance={allNoteSeance} />
                            </div>
                            :
                            <div>
                                Pas d'export pour cette sélection
                            </div>
                        }
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
