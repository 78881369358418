import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from '@material-tailwind/react';

import EnterSend from "../EnterSend";

import { NotificationManager } from 'react-notifications';

import axios from 'axios';

function MyModalDeleteEvaluationEleve(props) {

  function onModalClose(event) {
    props.onCloseModal(event, 2);
  }

  const [prenom, setPrenom] = React.useState();
  const [nom, setNom] = React.useState();
  const [note, setNote] = React.useState();

  const handleSubmit = () => {
    if(props.IsModalOpened === true) {
      deleteEvaluation()
    }
  };

  React.useEffect(() => {
    setPrenom(props.infoNote.prenom)
    setNom(props.infoNote.nom)
    setNote(props.infoNote.note)
  }, [props.infoNote]);

  function deleteEvaluation() {
    const params = {
      id_classe: props.infoNote.id_classe,
      id_seance: props.infoNote.id_seance,
      id_eleve: props.infoNote.id_eleve
    };
    
    axios.delete('https://eps.acces-editions.com/api/deleteNoteEleve', { params })
    .then((res) => {
      if(res.data[0] !== undefined)
      {
          NotificationManager.success('Évaluation supprimée', '', 3000);
          onModalClose(false);
          window.location.reload()
      } else {
          NotificationManager.success('Évaluation supprimée', '', 3000);
          onModalClose(false);
          window.location.reload()
      }
    })
  }

  return (
    <>
      <Modal size="sm" active={props.IsModalOpened} toggler={() => {return}}>
        <ModalHeader toggler={() => onModalClose(false)}>
            Supprimer l'évaluation
        </ModalHeader>
        <ModalBody>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Prénom
            </label>
            <span>{prenom}</span>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Nom
            </label>
            <span>{nom}</span>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Note
            </label>
            <span>{note}</span>
            </div>
        </ModalBody>
        <ModalFooter>
            <Button 
                color="red"
                buttonType="link"
                onClick={(e) => onModalClose(false)}
                ripple="dark"
            >
                Fermer
            </Button>

            <Button
                color="green"
                onClick={(e) => deleteEvaluation()}
                ripple="light"
            >
              <EnterSend handleSubmit={handleSubmit}/>
              Supprimer
            </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default MyModalDeleteEvaluationEleve;