import React, { useState } from 'react';
import StatusCard from 'components/StatusCard';
import ClasseNoteCardEval from 'components/ClasseNoteCardEval';
import ClasseNoteCard from 'components/ClasseNoteCard';
import { Image } from '@material-tailwind/react';

import BackButton from '../components/BackButton.js';

import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'

export default function Suivi(props) {
    const nbEleve = useSelector(state => state.eleveNb.eleve.nb_eleve)

    const [idClasse, setIdClasse] = useState();
    const [niveauClasse, setNiveauClasse] = useState();
    const [nomClasse, setNomClasse] = useState();
    const [positionEleve, setPositionEleve] = useState();

    const dispatch = useDispatch()

    const user_connected = useSelector(state => state.userConnected.user_connected)

    React.useEffect(() => {
        setIdClasse(props.location.state.detail.id_classe)
        setNiveauClasse(props.location.state.detail.niveau)
        setNomClasse(props.location.state.detail.nom_classe + ' ' + props.location.state.detail.nom_etablissement)
        setPositionEleve(props.location.state.detail.position)

        axios.get('https://eps.acces-editions.com/api/getEleve?id_classe='+props.location.state.detail.id_classe)
        .then((res) => {
            if(res.data[0] !== undefined)
            {
                dispatch({ type: 'user/eleveDetail', payload: res.data })
                dispatch({ type: 'user/eleveNb', payload: { nb_eleve: res.data.length }})
            } else {
                dispatch({ type: 'user/eleveDetail', payload: [] })
                dispatch({ type: 'user/eleveNb', payload: { nb_eleve: 0 }})
            }
        })
    }, [props, dispatch]);

    return (
        <>
            <div className="md:ml-64">
            <div>
                <BackButton />
                <div className="bg-light-blue-500 pt-14 pb-28 px-3 md:px-8 h-auto">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4">
                            <StatusCard
                                color="blue"
                                icon="school"
                                title="Classe"
                                amount={nomClasse !== undefined ? nomClasse : ''}
                            />
                            <StatusCard
                                color="pink"
                                icon="groups"
                                title="Nombre d'élèves"
                                amount={nbEleve !== undefined ? nbEleve.toString() : ''}
                            />
                            <StatusCard
                                color="green"
                                icon="child_care"
                                title="Niveau(x) classe"
                                amount={niveauClasse !== undefined ? niveauClasse : ''}
                            />
                            <div>
                                <div className="container mb-10 px-4" style={{ width: 'fit-content', float: 'right', textAlign: 'center' }}>
                                    <div className="w-fit bg-white rounded-xl shadow-md p-4">
                                        <div className="w-full flex-grow">
                                            <Image
                                                src={process.env.PUBLIC_URL + `/img/avatars/avatar_${user_connected.id_avatar}.png`}
                                                rounded
                                                alt="avatar"
                                                style={{ height: "90px" }}
                                            />
                                            <span>{user_connected.pseudo}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="px-3 md:px-8 h-auto -mt-24">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 px-4 mb-16">
                            <ClasseNoteCardEval idClasse={idClasse} positionEleve={positionEleve} suvi={true}/>
                        </div>
                    </div>
                </div>

                <div className="px-3 md:px-8 h-auto">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 px-4 mb-16">
                            <ClasseNoteCard idClasse={idClasse} positionEleve={positionEleve} suivi={false} />
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    );
}
