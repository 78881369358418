import { Card, CardRow, CardHeader, CardStatus, Icon } from '@material-tailwind/react';

export default function StatusCard({
    color,
    icon,
    title,
    amount,
}) {
    return (
        <div className="px-4 mb-10">
            <Card>
                <CardRow>
                    <CardHeader color={color} iconOnly className="mb-0">
                        <Icon name={icon} size="4xl" color="white" />
                    </CardHeader>

                    <CardStatus title={title} amount={amount} style={{ fontSize: "12px" }}/>
                </CardRow>
            </Card>
        </div>
    );
}
