const initialState = { user_connected: {id_user: '', connected: false, id_avatar: '', pseudo: '' }}

export default function userConnectedReducer(state = initialState, action) {
  switch (action.type) {
    case 'user/userDisconnect': {
      return {
        ...state,
        user_connected: {
          id_user: '',
          connected: false,
          id_avatar: '',
          pseudo: ''
        }
      }
    }
    case 'user/userConnected': {
      // Can return just the new todos array - no extra object around it
      return {
        ...state,
        user_connected: {
          id_user: action.payload.id_user,
          connected: action.payload.connected,
          id_avatar: action.payload.id_avatar,
          pseudo: action.payload.pseudo,
        }
      }
    }
    default:
      return state
  }
}