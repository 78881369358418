import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import AdminNavbar from './AdminNavbar';
import { Icon } from '@material-tailwind/react';
import { useSelector } from 'react-redux'
import MyModalActivation from './ActivationModal/MyModalActivation';

import Logo from 'assets/img/Logo_EPS_50.png';

export default function Sidebar() {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [showSidebar, setShowSidebar] = useState('-left-64');
    // const storeInfo = store.getState()
    const user = useSelector(state => state.userGlobal.user)
    
    function openFromParent(modalNumber) {
        switch(modalNumber) {
          case 1:
            setIsOpen(true);
            break;
          default:
            break;
        }
    }

    function handleCloseModal(event, data) {
        switch(data) {
          case 1:
            setIsOpen(false);
            break;
          default:
            break;
        }
    }

    return (
        <>
            <MyModalActivation
                IsModalOpened={modalIsOpen}
                onCloseModal={handleCloseModal}
                key={Math.random()}
            />
            <AdminNavbar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-10 py-4 px-6 transition-all duration-300`}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                    <a
                        href="/compte" className="mt-2 text-center w-full inline-block"
                    >
                        <div className="flex flex-wrap justify-center">
                          <img
                          src={Logo}
                          alt="logo"
                        />
                        </div>
                    </a>
                    <div className="flex flex-col">
                        <hr className="my-4 min-w-full" />

                        <ul className="flex-col min-w-full flex list-none">
                            <li className="rounded-lg mb-4" style={user.admin === true ? {display: 'block'} : {display: 'none'}}>
                                <NavLink
                                    to="/dashboard"
                                    exact
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="dashboard" size="2xl" />
                                    Dashboard
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2" style={user.admin === true ? {display: 'block'} : {display: 'none'}}>
                                <NavLink
                                    to="/settings"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="settings" size="2xl" />
                                    Options
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2 ">
                                <NavLink
                                    to="/compte"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="toc" size="2xl" />
                                    Compte
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2 text-gray-700" style={user.admin === true ? {display: 'block'} : {display: 'none'}}>
                                <NavLink
                                    to="/maps"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="map" size="2xl" />
                                    Maps
                                </NavLink>
                            </li>
                            {/*<li className="px-4 rounded-lg mb-2 text-gray-700">
                                <a
                                    href="https://demos.creative-tim.com/material-tailwind-kit-react/#/login"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center gap-4 text-sm font-light py-3"
                                >
                                    <Icon name="fingerprint" size="2xl" />
                                    Login
                                </a>
                            </li>
                            <li className="px-4 rounded-lg mb-2 text-gray-700">
                                <a
                                    href="https://demos.creative-tim.com/material-tailwind-kit-react/#/register"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center gap-4 text-sm font-light py-3"
                                >
                                    <Icon name="list_alt" size="2xl" />
                                    Register
                                </a>
                            </li>
                            <li className="px-4 rounded-lg mb-2 text-gray-700">
                                <a
                                    href="https://demos.creative-tim.com/material-tailwind-kit-react/#/landing"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center gap-4 text-sm font-light py-3"
                                >
                                    <Icon name="web" size="2xl" />
                                    Landing Page
                                </a>
                            </li>
                            <li className="px-4 rounded-lg mb-2 text-gray-700">
                                <a
                                    href="https://demos.creative-tim.com/material-tailwind-kit-react/#/profile"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center gap-4 text-sm font-light py-3"
                                >
                                    <Icon name="account_circle" size="2xl" />
                                    Profile Page
                                </a>
                            </li>*/}
                        </ul>

                        <ul className="flex-col min-w-full flex list-none absolute bottom-0">
                            {/*<li className="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 px-4 rounded-lg text-white mb-2">
                                <a
                                    href="https://material-tailwind.com/documentation/quick-start"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex items-center gap-4 text-sm font-light py-3"
                                >
                                    <Icon name="description" size="2xl" />
                                    Documentation
                                </a>
                            </li>*/}
                            <li className="bg-gradient-to-tr from-yellow-500 to-yellow-500 px-4 rounded-lg text-white mb-5">
                                <button
                                    rel="noreferrer"
                                    className="flex items-center justify-center gap-4 text-sm font-light py-3 w-full focus:outline-none"
                                    onClick={(e) => {openFromParent(1)}}
                                >
                                    CODE D'ACTIVATION
                                </button>
                            </li>
                            <li className="bg-gradient-to-tr from-green-500 to-green-500 px-4 rounded-lg text-white mb-5">
                                <a
                                    href="https://acces-editions.com/acces-eps-cgvu"
                                    rel="noreferrer"
                                    target="_blank"
                                    className="flex items-center justify-center gap-4 text-sm font-light py-3"
                                >
                                    CGU - CGV
                                </a>
                            </li>
                            <li className="bg-gradient-to-tr from-gray-500 to-gray-500 px-4 rounded-lg text-white mb-5">
                                <a
                                    href="/logout"
                                    rel="noreferrer"
                                    className="flex items-center justify-center gap-4 text-sm font-light py-3"
                                >
                                    Déconnexion
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
