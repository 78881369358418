import React, { useState } from 'react';
import StatusCard from 'components/StatusCard';
import TableCard from 'components/TableCard';
import moment from 'moment'
import 'moment/locale/fr'

import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'

export default function Dashboard() {
    const user = useSelector(state => state.userGlobal.user)

    const [userInfo, setUserInfo] = useState([]);
    const [userAbo, setUserAbo] = useState([]);
    const [cycle, setCycle] = useState();

    const dispatch = useDispatch()
    
    React.useEffect(() => {
        // GET USER DETAILS
        axios.get('https://eps.acces-editions.com/api/showUser?id_compte='+user.id_compte)
        .then((res) => {
            if(res.data.allPseudo[0] !== undefined && res.data.allPseudo[0].pseudo)
            {
                dispatch({ type: 'user/userDetail', payload: res.data.allPseudo })
                setUserInfo(res.data.allPseudo)
            }
        })

        // GET ABONNEMENT
        const formData = new FormData();
        formData.append('id_compte', user.id_compte)

        axios.post('https://eps.acces-editions.com/api/statutAbonnement', formData)
        .then((res) => {
            if(res.data[0] !== undefined && res.data[0].id_type_abonnements)
            {
                dispatch({ type: 'user/userAbo', payload: res.data[0] })
                setUserAbo(res.data[0])
                var i = 1
                var cycle_value=''
                while(i <= 3) {
                    var variable = 'cycle_'+i
                    if(res.data[0][variable] === 1)
                    {
                        if(i === 3) {
                            cycle_value += 'Cycle ' + i
                        } else {
                            cycle_value += 'Cycle ' + i + ', '
                        }
                    }
                    i++
                }
                setCycle(cycle_value)
            }
        })
    }, [user.id_compte, dispatch]);

    return (
        <>
            <div className="md:ml-64">
            <div className="bg-light-grey-500 pt-14 pb-28 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4">
                        <StatusCard
                            color="pink"
                            icon="account_circle"
                            title="Compte"
                            amount={user.email !== undefined ? user.email : ''}
                        />
                        <StatusCard
                            color="orange"
                            icon="groups"
                            title={userInfo.length > 1 ? "Nombre d'utilisateurs" : "Nombre d'utilisateur"}
                            amount={userInfo.length !== undefined ? userInfo.length.toString() : ''}
                        />
                        <StatusCard
                            color="purple"
                            icon="assignment_turned_in"
                            title="Date début d'abonnement"
                            amount={userAbo.date_debut !== undefined ? moment(userAbo.date_debut).format('DD MMMM YYYY') : ''}
                        />
                        <StatusCard
                            color="blue"
                            icon="event_busy"
                            title="Date de fin"
                            amount={userAbo.date_fin !== undefined ? moment(userAbo.date_fin).format('DD MMMM YYYY') : ''}
                        />
                        <StatusCard
                            color="green"
                            icon="update"
                            title="Durée restante"
                            amount={moment(userAbo.date_fin).diff(moment(), 'days') <= 0 ? 'Abonnement expiré' : moment(userAbo.date_fin).diff(moment(), 'days') + 'j'}
                        />
                        <StatusCard
                            color="yellow"
                            icon="data_usage"
                            title="Cycle abonnement"
                            amount={cycle !== undefined ? cycle : ''}
                        />
                    </div>
                </div>
            </div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableCard />
                    </div>
                </div>
            </div>
            </div>
        </>
    );
}
