import React from 'react';
import { Button } from '@material-tailwind/react';
import EnterSend from "../components/EnterSend";
import { NotificationManager } from 'react-notifications';
import { Card, CardHeader, CardBody } from '@material-tailwind/react';

import MyModalCreateAbo from '../components/AdminModal/MyModalCreateAbo';
import MyModalModifAbo from '../components/AdminModal/MyModalModifAbo';
import MyModalDeleteAbo from '../components/AdminModal/MyModalDeleteAbo';
import MyModalModifUser from '../components/AdminModal/MyModalModifUser';
import MyModalDeleteUser from '../components/AdminModal/MyModalDeleteUser';

import axios from 'axios';

import moment from 'moment'
import 'moment/locale/fr'

export default function Dashboard(props) {
    const [email, setEmail] = React.useState('');
    const [allEmail, setAllEmail] = React.useState([]);
    const [aboInfo, setAboInfo] = React.useState([]);
    const [userInfo, setUserInfo] = React.useState([]);

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalIsOpen2, setIsOpen2] = React.useState(false);
    const [modalIsOpen3, setIsOpen3] = React.useState(false);
    const [modalIsOpen4, setIsOpen4] = React.useState(false);
    const [modalIsOpen5, setIsOpen5] = React.useState(false);
    const [indexSelected, setIndexSelected] = React.useState(null);
    const [indexSelectedAbo, setIndexSelectedAbo] = React.useState(null);

    const handleSubmit = () => {
        searchEmail(email)
    };

    function openFromParent(modalNumber, index) {
        switch(modalNumber) {
            case 1:
                setIsOpen(true);
                break;
            case 2:
                setIsOpen2(true);
                setIndexSelectedAbo(index)
                break;
            case 3:
                setIsOpen3(true);
                setIndexSelectedAbo(index)
                break;
            case 4:
                setIsOpen4(true);
                setIndexSelected(index)
                break;
            case 5:
                setIsOpen5(true);
                setIndexSelected(index)
                break;
            default:
                break;
        }
    }

    function handleCloseModal(event, data) {
        switch(data) {
            case 1:
                setIsOpen(false);
                break;
            case 2:
                setIsOpen2(false);
                break;
            case 3:
                setIsOpen3(false);
                break;
            case 4:
                setIsOpen4(false);
                break;
            case 5:
                setIsOpen5(false);
                break;
            default:
                break;
        }
    }

    function searchEmail(email) {
        const formData = new FormData();
        formData.append('email', email)

        axios.post('https://eps.acces-editions.com/api/adminSearchEmail', formData)
        .then((res) => {
            if(res.data.length > 0)
            {
                setAboInfo([])
                setUserInfo([])
                setAllEmail(res.data)
            } else {
                NotificationManager.error('Aucun compte associé à cet email !', '', 3000);
            }
        })
    }

    function getInfoUser(id_compte) {
        const formData = new FormData();
        formData.append('id_compte', id_compte)

        axios.post('https://eps.acces-editions.com/api/adminSearchUser', formData)
        .then((res) => {
            if(res.data.length > 0)
            {
                setAboInfo(res.data[0].abo)
                setUserInfo(res.data[0].user)
            } else {
                NotificationManager.error('Une erreur est survenue', '', 3000);
            }
        })
    }

    return (
        <>
            <MyModalCreateAbo
                IsModalOpened={modalIsOpen}
                onCloseModal={handleCloseModal}
                getInfoUser={getInfoUser}
                key={Math.random()}
            />

            <MyModalModifAbo
                IsModalOpened={modalIsOpen2}
                onCloseModal={handleCloseModal}
                getInfoUser={getInfoUser}
                allInfo={aboInfo}
                indexSelected={indexSelectedAbo}
                key={Math.random()}
            />

            <MyModalDeleteAbo
                IsModalOpened={modalIsOpen3}
                onCloseModal={handleCloseModal}
                getInfoUser={getInfoUser}
                allInfo={aboInfo}
                indexSelected={indexSelectedAbo}
                key={Math.random()}
            />

            <MyModalModifUser
                IsModalOpened={modalIsOpen4}
                onCloseModal={handleCloseModal}
                getInfoUser={getInfoUser}
                userInfo={userInfo}
                indexSelected={indexSelected}
                key={Math.random()}
            />

            <MyModalDeleteUser
                IsModalOpened={modalIsOpen5}
                onCloseModal={handleCloseModal}
                getInfoUser={getInfoUser}
                userInfo={userInfo}
                indexSelected={indexSelected}
                key={Math.random()}
            />

            <div className="bg-light-grey-500 pt-14 pb-28 px-3 md:px-8 h-auto md:ml-64" />

            <div className="px-3 md:px-8 -mt-24 md:ml-64">
                <Card>
                    <CardHeader color="blue" contentPosition="left" className="h-auto">
                        <h2 className="text-white text-2xl">Liste email</h2>
                        <label className="block text-gray-700 text-sm font-bold mb-2 text-white" htmlFor="username">
                            Recherche client par Email:
                        </label>
                        <div className="w-full inline-flex">
                            <input id="searchEmail" value={email} className="w-96 shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => { setEmail(e.target.value)}}/>
                            <div className="ml-6">
                                <Button
                                    color="green"
                                    ripple="light"
                                    onClick={(e) => {searchEmail(email)}}
                                >
                                  <EnterSend handleSubmit={handleSubmit}/>
                                  Rechercher
                                </Button>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div className="overflow-x-auto">
                            <table className="items-center w-full bg-transparent border-collapse">
                                <thead>
                                    <tr>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Id Compte
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Email
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allEmail.map((row, index) => {
                                        return (
                                            <tr key={row.id_compte} style={{display: 'table-row'}}>
                                                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    { row.id_compte }
                                                </th>
                                                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    { row.email }
                                                </th>
                                                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    <div className="flex">
                                                        <div className="w-10 h-10 rounded-full border-2 border-white">
                                                            <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-4 rounded" style={{display: 'inline'}} onClick={(e) => { getInfoUser(row.id_compte) }}>Consulter</button>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>

                {aboInfo.length > 0 && (
                    aboInfo[0].no_abo === false || aboInfo[0].no_abo === undefined ? (
                        <Card className="mt-20">
                            <CardHeader color="blue" contentPosition="left" className="h-auto">
                                <h2 className="text-white text-2xl">Info abonnement</h2>
                            </CardHeader>
                            <CardBody>
                                <div className="overflow-x-auto">
                                    <table className="items-center w-full bg-transparent border-collapse">
                                        <thead>
                                            <tr>
                                                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                                    Date paiement
                                                </th>
                                                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                                    Date début abonnement
                                                </th>
                                                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                                    Date fin abonnement
                                                </th>
                                                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                                    Cycle 1
                                                </th>
                                                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                                    Cycle 2
                                                </th>
                                                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                                    Cycle 3
                                                </th>
                                                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {aboInfo.map((row, index) => {
                                                return (
                                                    <tr key={row.id_abonnement} style={{display: 'table-row'}}>
                                                        <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                            { moment(row.date_paiement).format('DD MMMM YYYY') }
                                                        </th>
                                                        <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                            { moment(row.date_debut).format('DD MMMM YYYY') }
                                                        </th>
                                                        <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                            { moment(row.date_fin).format('DD MMMM YYYY') }
                                                        </th>
                                                        <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                            { row.cycle_1 }
                                                        </th>
                                                        <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                            { row.cycle_2 }
                                                        </th>
                                                        <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                            { row.cycle_3 }
                                                        </th>
                                                        <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                            <div className="flex">
                                                                <div className="w-10 h-10 rounded-full border-2 border-white">
                                                                    <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-4 rounded" style={{display: 'inline'}} onClick={(e) => {openFromParent(2, index)}}>Modifier</button>
                                                                    <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 mr-4 rounded" style={{display: 'inline'}} onClick={(e) => {openFromParent(3, index)}}>Supprimer</button>
                                                                </div>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                ) : (
                    <Card className="mt-20">
                        <CardHeader color="blue" contentPosition="left" className="h-auto">
                            <h2 className="text-white text-2xl">Info abonnement</h2>
                        </CardHeader>
                        <CardBody>
                            <div className="flex center items-center">
                                <div className="flex pr-10">Aucun abonnement</div>
                                <div className="inline-flex rounded-full border-2 border-white">
                                    <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-4 rounded" style={{display: 'inline'}} onClick={(e) => {openFromParent(1)}}>Créer un abonnement</button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                )
            )}

            {userInfo.length > 0 && (
                userInfo[0].no_user === false || userInfo[0].no_user === undefined ? (
                    <Card className="mt-20">
                        <CardHeader color="blue" contentPosition="left" className="h-auto">
                            <h2 className="text-white text-2xl">Info utilisateur</h2>
                        </CardHeader>
                        <CardBody>
                            <div className="overflow-x-auto">
                                <table className="items-center w-full bg-transparent border-collapse">
                                    <thead>
                                        <tr>
                                            <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                                Pseudo
                                            </th>
                                            <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                                Code Secret
                                            </th>
                                            <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                                Device 1
                                            </th>
                                            <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                                Mac adresse 1
                                            </th>
                                            <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                                Device 2
                                            </th>
                                            <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                                Mac adresse 2
                                            </th>
                                            <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userInfo.map((row, index) => {
                                            return (
                                                <tr key={row.id_user} style={{display: 'table-row'}}>
                                                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                        { row.pseudo }
                                                    </th>
                                                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                        { row.pin_code }
                                                    </th>
                                                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                        { row.device_name_1 }
                                                    </th>
                                                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                        { row.mac_adress_1 }
                                                    </th>
                                                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                        { row.device_name_2 }
                                                    </th>
                                                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                        { row.mac_adress_2 }
                                                    </th>
                                                    <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                        <div className="flex">
                                                            <div className="w-10 h-10 rounded-full border-2 border-white">
                                                                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-4 rounded" style={{display: 'inline'}} onClick={(e) => {openFromParent(4, index)}}>Modifier</button>
                                                                <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 mr-4 rounded" style={{display: 'inline'}} onClick={(e) => {openFromParent(5, index)}}>Supprimer</button>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                ) : (
                    <Card className="mt-20">
                        <CardHeader color="blue" contentPosition="left" className="h-auto">
                            <h2 className="text-white text-2xl">Info abonnement</h2>
                        </CardHeader>
                        <CardBody>
                        <div className="flex center items-center">
                                <div className="flex pr-10">Aucun utilisateur</div>
                            </div>
                        </CardBody>
                    </Card>
                )
            )}
            </div>
        </>
    );
}
