import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from '@material-tailwind/react';


import EnterSend from "../EnterSend";

import { useDispatch } from 'react-redux'

import { NotificationManager } from 'react-notifications';

import axios from 'axios';

function MyModalDeleteClasse(props) {

  function onModalClose(event) {
    props.onCloseModal(event, 3);
  }

  const [idEleve, setIdEleve] = React.useState();
  const [nomEleve, setNomEleve] = React.useState();
  const [prenomEleve, setPrenomEleve] = React.useState();
  const [niveauEleve, setNiveauEleve] = React.useState(0);

  const handleSubmit = () => {
    if(props.IsModalOpened === true) {
      deleteEleve()
    }
  };

  React.useEffect(() => {
    setIdEleve(props.eleveInfo.id_eleve)
    setNomEleve(props.eleveInfo.nom)
    setPrenomEleve(props.eleveInfo.prenom)
    setNiveauEleve(props.eleveInfo.niveau)
  }, [props.eleveInfo.id_eleve,props.eleveInfo.nom,props.eleveInfo.prenom,props.eleveInfo.niveau]);

  const dispatch = useDispatch()

  function deleteEleve() {
    const params = {
      id_eleve: idEleve,
    };
    
    axios.delete('https://eps.acces-editions.com/api/removeEleve', { params })
    .then((res) => {
      axios.get('https://eps.acces-editions.com/api/getEleve?id_classe='+props.classeInfo.idClasse)
      .then((res) => {
        if(res.data.length > 0)
        {
          NotificationManager.success('Élève retiré', '', 3000);
          dispatch({ type: 'user/eleveDetail', payload: res.data })
          dispatch({ type: 'user/eleveNb', payload: { nb_eleve: res.data.length }})
          onModalClose(false);
        } else {
          NotificationManager.success('Élève retiré', '', 3000);
          dispatch({ type: 'user/eleveDetail', payload: [] })
          dispatch({ type: 'user/eleveNb', payload: { nb_eleve: 0 }})
          onModalClose(false);
        }
      })
    })
  }


  return (
    <>
      <Modal size="sm" active={props.IsModalOpened} toggler={() => {return}}>
        <ModalHeader toggler={() => onModalClose(false)}>
            Retirer l'élève
        </ModalHeader>
        <ModalBody>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Nom de l'élève
            </label>
            <span>{nomEleve}</span>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Prénom de l'élève
            </label>
            <span>{prenomEleve}</span>
            </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Niveau
            </label>
            <span>{niveauEleve}</span>
          </div>
        </ModalBody>
        <ModalFooter>
            <Button 
                color="red"
                buttonType="link"
                onClick={(e) => onModalClose(false)}
                ripple="dark"
            >
                Fermer
            </Button>

            <Button
                color="green"
                onClick={(e) => deleteEleve()}
                ripple="light"
            >
              <EnterSend handleSubmit={handleSubmit}/>
              Retirer
            </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default MyModalDeleteClasse;