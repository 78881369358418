import React from 'react';

export default function EnterSend({handleSubmit}) {
	React.useEffect(() => {
		const keyDownHandler = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit()
          }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
	}, [handleSubmit]);

    return (
        <>
        </>
    )
}
