import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from '@material-tailwind/react';

import EnterSend from "../EnterSend";

import { NotificationManager } from 'react-notifications';

import axios from 'axios';

function MyModalDeleteEvaluation(props) {

  function onModalClose(event) {
    props.onCloseModal(event, 1);
  }

  const [titreDiscipline, setTitreDiscipline] = React.useState();
  const [titreActivite, setTitreActivite] = React.useState();
  const [titreObjectif, setTitreObjectif] = React.useState();
  const [idClasseModal, setIdClasseModal] = React.useState();

  const handleSubmit = () => {
    if(props.IsModalOpened === true) {
      deleteEvaluation()
    }
  };

  React.useEffect(() => {
    setTitreDiscipline(props.infoNoteModal.nom_sequence)
    setTitreActivite(props.rowModal.titre_activite)
    setTitreObjectif(props.rowModal.objectif)
    setIdClasseModal(props.idClasse)
  }, [props.infoNoteModal.nom_sequence, props.rowModal.titre_activite, props.rowModal.objectif, props.idClasse]);

  function deleteEvaluation() {
    const params = {
      id_classe: idClasseModal,
      id_seance: props.infoNoteModal.id_seance,
    };
    
    axios.delete('https://eps.acces-editions.com/api/deleteNote', { params })
    .then((res) => {
      if(res.data[0] !== undefined)
      {
          NotificationManager.success('Évaluation supprimée', '', 3000);
          onModalClose(false);
          window.location.reload()
      } else {
          NotificationManager.success('Évaluation supprimée', '', 3000);
          onModalClose(false);
          window.location.reload()
      }
    })
  }

  return (
    <>
      <Modal size="sm" active={props.IsModalOpened} toggler={() => {return}}>
        <ModalHeader toggler={() => onModalClose(false)}>
            Supprimer l'évaluation
        </ModalHeader>
        <ModalBody>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Discipline
            </label>
            <span>{titreDiscipline}</span>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Activité
            </label>
            <span>{titreActivite}</span>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Objectif
            </label>
            <span>{titreObjectif}</span>
            </div>
        </ModalBody>
        <ModalFooter>
            <Button 
                color="red"
                buttonType="link"
                onClick={(e) => onModalClose(false)}
                ripple="dark"
            >
                Fermer
            </Button>

            <Button
                color="green"
                onClick={(e) => deleteEvaluation()}
                ripple="light"
            >
              <EnterSend handleSubmit={handleSubmit}/>
              Supprimer
            </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default MyModalDeleteEvaluation;