import React, { useState } from 'react';

import { StyleSheet } from '@react-pdf/renderer';

import axios from 'axios';

import moment from 'moment'
import 'moment/locale/fr'

import star0 from 'assets/img/Fichier 1.png';
import star1 from 'assets/img/Fichier 2.png';
import star2 from 'assets/img/Fichier 3.png';
import star3 from 'assets/img/Fichier 4.png';
import star4 from 'assets/img/Fichier 5.png';

export default function CreatePdfClasse({idClasse, allNoteSeance, cycleSelected, competenceSelected, sequenceSelected, categorieSelected}) {
	const [nbNote, setNbNote] = useState([1])
	const [getClasse, setClasse] = useState([{nom_classe: '', nbEleves: 0, niveau: ''}]);
	const [getEleve, setEleve] = useState([]);
	const [getNoteEleveSceance, setNoteEleveSceance] = useState([]);
    // const [getNoteClasseSeanceSpec, setNoteClasseSeanceSpec] = useState([]);
    const [getAllnoteSeance, setAllNoteSeance] = useState([[]])

    React.useEffect(() => {
    	const chunkArray = (arr, size) => {
	  		if(arr.length > size) {
				return [arr.slice(0, size), ...chunkArray(arr.slice(size), size)]
			} else {
				return [arr]
			}
	    }

	    const setNote = async (allNote) => {
			const newArray = []
			await Promise.all(allNote.map(async (row, index) => {
			   	return await axios.get('https://eps.acces-editions.com/api/getNoteEleveSceance?id_classe='+idClasse+'&id_seance='+row.id)
			    .then((res) => {
			        if(res.data !== undefined)
			        {
			        	newArray.push({index: row.id, data: res.data})
			        }
			    })
		    }))
		    newArray.sort((a, b) => a.index > b.index ? 1 : -1)
		    setNoteEleveSceance(newArray)
		}

		setNoteEleveSceance([])

    	setClasse([{nom_classe: '', nbEleves: 0, niveau: ''}])
    	setEleve([])

    	var eachNbNote = Math.round(allNoteSeance.length / 6) > 0 ? Math.round(allNoteSeance.length / 6) : 1
    	var content = []
    	for (var i = 1; i <= eachNbNote; i++) {
            content.push(i)
        }
		setNbNote(content)


		var tmpArray = chunkArray(allNoteSeance, 6)
		setAllNoteSeance(tmpArray)

		setNote(allNoteSeance)

		axios.get('https://eps.acces-editions.com/api/getEleve?id_classe='+idClasse)
	    .then((res) => {
	        if(res.data[0] !== undefined)
	        {
	            setEleve(res.data)
	        }
	    })

    	axios.get('https://eps.acces-editions.com/api/getMyClasseId?id_classe='+idClasse)
	    .then((res) => {
	        if(res.data[0] !== undefined)
	        {
	            setClasse(res.data)
	        }
	    })

        window.scrollTo(0, 0)

    }, [idClasse, allNoteSeance]);

	const styles = StyleSheet.create({
	  display: {
	  	display: 'none'
	  },
	  page: {
	  	width: '842px',
	  },
	  header: {
	  	height: '110px'
	  },
	  card: {
	    display: 'inline-flex',
	    justifyContent: 'center',
	    fontSize: '10px',
	    fontWeight: 'bold',
	    padding: '5px',
	    color: 'black',
	    wordSpacing: '4px',
	    alignItems: 'center',
	    paddingLeft: '10px',
    	paddingRight: '10px'
	  },
	  card2: {
	  	backgroundColor: '#03A9F4',
	  	color: 'white',
	    textAlign: 'center',
	    fontSize: '10px',
	    paddingBottom: '10px',
	    width: '-webkit-fill-available',
	    justifyContent: 'center',
	    alignItems: 'center',
	    borderRight: '1px solid white',
    	paddingLeft: '5px',
    	paddingRight: '5px'
	  },
	  card2Last: {
	  	backgroundColor: '#03A9F4',
	  	color: 'white',
	    textAlign: 'center',
	    fontSize: '10px',
	    paddingBottom: '10px',
	    width: '-webkit-fill-available',
	    justifyContent: 'center',
	    alignItems: 'center',
    	paddingLeft: '5px',
    	paddingRight: '5px'
	  },
	  card3: {
	  	display: 'inline-flex',
	    justifyContent: 'center',
	    fontSize: '12px',
	    fontWeight: 'bold',
	    padding: '5px',
	    color: 'black',
	    wordSpacing: '4px',
	    alignItems: 'center'
	  },
	  headerText: {
	  	fontSize: '12px',
	  	display: 'inline-flex',
	    justifyContent: 'center',
	    fontWeight: 'bold',
	    padding: '10px',
	    color: 'black',
	    wordSpacing: '4px',
	    alignItems: 'center'
	  },
	  headerText2: {
	  	fontSize: '12px',
	  	display: 'inline-block',
	    fontWeight: 'bold',
	    padding: '10px',
	    color: 'black',
	    wordSpacing: '4px'
	  },
	  eval: {
	  	fontSize: '10px',
	  	textAlign: 'center'
	  },
	  centerImage: {
	  	textAlign: 'center',
	    display: 'block',
	    marginLeft: 'auto',
	    marginRight: 'auto'
	  },
	  border: {
	  	border: '2px solid #03A9F4'
	  },
	  block: {
	  	display: 'inline-block'
	  },
	  border2: {
	  	borderWidth: '0px 2px 2px 2px',
	  	borderColor: '#03A9F4'
	  },
	  border3: {
	  	borderWidth: '2px 2px 2px 0px',
	  	borderColor: '#03A9F4'
	  },
	  whiteRow: {
	  	backgroundColor: 'white'
	  },
	  greyRow: {
	  	backgroundColor: '#D5D5D5'
	  },
	  rowCol: {
	  	minHeight: '25px',
	  	maxHeight: '25px'
	  },
	  edito: {
	  	fontSize: '9px',
	  	color: 'grey'
	  },
	  textUnderline: {
	  	textDecoration: 'underline',
	  	fontWeight: '300',
	  	fontSize: '10px'
	  },
	  textDescription: {
	  	fontSize: '11px'
	  },
	  borderBlack: {
	  	borderRight: '2px solid black'
	  }
	});

	return(
		<div style={styles.display}>
			<div className="col-span-1 col-span-2 col-span-3 col-span-4 col-span-5 col-span-6 col-span-7 col-span-8 col-span-9 col-span-10 col-span-11 col-span-12" ></div>	
			{nbNote.map((nbRow, nbIndex) => {
				return(
					<div key={"export_"+nbIndex} id={"export_activity_"+(nbIndex + 1)} style={styles.page}>
						<div className="pt-3 px-3 md:px-3" style={styles.header}>
		                    <div className="container mx-auto max-w-full">
		                    	<div className="grid grid-rows-1 grid-flow-col">
		                        	<div className="col-span-3" style={styles.headerText2}>
		                        		<div style={styles.textUnderline}>{'Export ' + (nbIndex + 1) + '/' + nbNote.length} </div>
		                        		<div style={styles.textDescription}>{getClasse[0].nom_classe}</div>
		                        		<div style={styles.textDescription}>{'Niveau : ' + getClasse[0].niveau}</div>
		                        		<div style={styles.textDescription}>{'Effectif : ' + getClasse[0].nbEleves}</div>
		                        		<div style={styles.edito}>{"Document édité le : " + moment().format('DD/MM/YY')}</div>
		                        	</div>
		                       	</div>
		                        <div className="grid grid-cols-12 lg:grid-cols-12 xl:grid-cols-12 gap-x-2">
		                        	<div className="col-start-3 col-span-12" style={styles.border}>
		                        		<div className="grid grid-cols-12 lg:grid-cols-12 xl:grid-cols-12">
				                            <div className="col-span-4" style={styles.headerText}>{"Cycle " + cycleSelected}</div>
				                            <div className="col-span-4" style={styles.headerText}>{competenceSelected.nom}</div>
				                        	<div className="col-span-4" style={styles.headerText}>{sequenceSelected.nom}</div>
		                        		</div>
		                        	</div>
		                        </div>
		                        <div className="grid grid-cols-12 lg:grid-cols-12 xl:grid-cols-12 gap-x-2">
		                            <div className="col-start-3 col-span-12" style={{...styles.border2, ...styles.card3}}>{categorieSelected.nom}</div>
		                        </div>
		                        <div className="grid grid-cols-12 lg:grid-cols-12 xl:grid-cols-12 gap-x-2">
		                        	<div className="col-start-3 col-span-12 flex" style={styles.border2}>
				                        {getAllnoteSeance[nbIndex].map((row, index) => {
				                        	return(
				                        		<div key={"allNoteSeance_"+index} className="flex flex-wrap" style={(getAllnoteSeance[nbIndex].length - 1) === index ? styles.card2Last : styles.card2}>{row.objectif}</div>
				                        	)
				                        })}
				                    </div>
			                    </div>
		                        <div className="grid grid-cols-12 lg:grid-cols-12 xl:grid-cols-12">
			                        <div id="liste_eleve"className="grid grid-flow-row auto-rows-max gap-x-2 col-span-2" style={styles.border}>
				                        {getEleve !== undefined ? getEleve.sort((a, b) => a.id_eleve > b.id_eleve ? 1 : -1).map((row3, index3) => {
				                        	return(<div key={"getEleve"+index3} className={"col-span-12"} style={{...styles.card, ...styles.rowCol, ...index3 % 2 === 0 ? styles.whiteRow : styles.greyRow}}><span>{row3.prenom + " " + row3.nom}</span></div>)
				                        }) : <div></div>}
			                        </div>
			                    	<div className="col-start-3 col-span-12 flex" style={styles.border3}>
				                        {getAllnoteSeance[nbIndex].map((row, index) => {
				                        	var count = nbIndex > 0 ? index + (6 * nbIndex) : index
				                        	return(
					                        	<div key={'allNoteSeance_'+count} className={"flex flex-wrap"} style={(getAllnoteSeance[nbIndex].length - 1) === index ? {} : {...styles.borderBlack}}>
									        	    {getEleve !== undefined ? getEleve.sort((a, b) => a.id_eleve > b.id_eleve ? 1 : -1).map((row2, index2) => {
									        	    	if(getNoteEleveSceance !== undefined) {
									        	    		if(getNoteEleveSceance[count] !== undefined) {
										        	    		var result = getNoteEleveSceance[count].data.find(({id_eleve}) => id_eleve === row2.id_eleve)
										        	    	}
									        	    	}
									        	    	return(
									        	    		<div key={'getNoteEleveSceance_'+index2} className="container mx-auto max-w-full" style={{...styles.rowCol, ...index2 % 2 === 0 ? styles.whiteRow : styles.greyRow}}>
																<div className={"grid grid-cols-12 lg:grid-cols-12 xl:grid-cols-12 gap-x-2"}>
																	<div className={"col-span-12"} style={styles.card}>{result === undefined ? <img src={star4} alt="logo" /> : result.note === 4 ? <img src={star0} alt="logo" /> : result.note === 3 ? <img src={star1} alt="logo" /> : result.note === 2 ? <img src={star2} alt="logo" /> : result.note === 1 ? <img src={star3} alt="logo" /> : result.note === 0 ? <img src={star4} alt="logo" /> : <img src={star4} alt="logo" />  }</div>
																</div>
															</div>
												        )
									        	    }) : <div></div>}
								            	</div>
						                    )
				                        })}
				                    </div>
								</div>
		                    </div>
		                </div>
		            </div>
		            )
	            })}
			</div>
	)
}