import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from '@material-tailwind/react';

import EnterSend from "../EnterSend";

import { useSelector } from 'react-redux'

import { NotificationManager } from 'react-notifications';

import axios from 'axios';

function MyModalActivation({IsModalOpened, onCloseModal}) {

  const user = useSelector(state => state.userGlobal.user)

  const [codeActivation, setCodeActivation] = React.useState('')

  const handleSubmit = () => {
    if(IsModalOpened === true) {
      activateCode()
    }
  }

  function onModalClose(event) {
    onCloseModal(event, 1);
  }

  React.useEffect(() => {
    setCodeActivation()
  }, []);

  function activateCode() {
    console.log(codeActivation)
    const formData = new FormData();

    formData.append('id_compte', user.id_compte)
    formData.append('code_activation', codeActivation)

    axios.post('https://eps.acces-editions.com/api/activationCode', formData)
    .then((res) => {
      console.log(res)
      if(res.data.statut === "KO")
      {
        NotificationManager.error(res.data.results, '', 3000);
      } else {
        NotificationManager.success(res.data.results, '', 3000);
        onModalClose(false);
      }
    })
  }

  return (
    <>
      <Modal size="md" active={IsModalOpened} toggler={() => {return}}>
        <ModalHeader toggler={() => onModalClose(false)}>
            Entrez le code d'activation
        </ModalHeader>
        <ModalBody>
          <div style={{ textAlign: 'center' }}>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                Code unique de votre guide de l'enseignant
              </label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type='text' onInput={(e) => { setCodeActivation(e.target.value)} } />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
            <Button 
                color="red"
                buttonType="link"
                onClick={(e) => onModalClose(false)}
                ripple="dark"
            >
              Fermer
            </Button>
            <Button
                color="green"
                onClick={(e) => activateCode()}
                ripple="light"
            >
              <EnterSend handleSubmit={handleSubmit}/>
              Valider
            </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default MyModalActivation;