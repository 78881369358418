import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from '@material-tailwind/react';

import EnterSend from "../EnterSend";

import { NotificationManager } from 'react-notifications';

import axios from 'axios';

function MyModalModifUser({IsModalOpened, onCloseModal, userInfo, getInfoUser, indexSelected}) {

	const [idUser, setIdUser] = React.useState('');
	const [idCompte, setIdCompte] = React.useState('');
	const [deviceName1, setDeviceName1] = React.useState('');
	const [deviceName2, setDeviceName2] = React.useState('');
	const [macAdress1, setMacAdress1] = React.useState('');
	const [macAdress2, setMacAdress2] = React.useState('');
	const [pseudo, setPseudo] = React.useState('');
	const [avatar, setAvatar] = React.useState('');
	const [codeSecret, setCodeSecret] = React.useState('');

	const handleSubmit = () => {
		if(IsModalOpened === true) {
			modifUser()
		}
	}

	function onModalClose(event) {
		onCloseModal(event, 4);
	}

	React.useEffect(() => {
		if(indexSelected !== undefined && indexSelected !== null && userInfo.length > 0) {
			setIdCompte(userInfo[indexSelected].id_compte)
			setIdUser(userInfo[indexSelected].id_user)
			setCodeSecret(userInfo[indexSelected].pin_code)
			setDeviceName1(userInfo[indexSelected].device_name_1)
			setDeviceName2(userInfo[indexSelected].device_name_2)
			setMacAdress1(userInfo[indexSelected].mac_adress_1)
			setMacAdress2(userInfo[indexSelected].mac_adress_2)
			setAvatar(userInfo[indexSelected].id_avatar)
			setPseudo(userInfo[indexSelected].pseudo)
			return true
		}
	}, [indexSelected, userInfo]);

	function modifUser() {
		if(pseudo !== '' && codeSecret !== '') {
			const formData = new FormData();

			formData.append('id_compte', idCompte)
			formData.append('id_user', idUser)
			formData.append('new_pin_code', codeSecret)
			formData.append('mac_adress_1', macAdress1 === '' ? null : macAdress1)
			formData.append('mac_adress_2', macAdress2 === '' ? null : macAdress2)
			formData.append('device_name_1', deviceName1 === '' ? null : deviceName1)
			formData.append('device_name_2', deviceName2 === '' ? null : deviceName2)
			formData.append('id_avatar', avatar)
			formData.append('pseudo', pseudo)

			axios.post('https://eps.acces-editions.com/api/updateAllUserInfo', formData)
			.then((res) => {
			  if(res.status === 200)
			  {
			    NotificationManager.success('Utilisateur modifié', '', 3000);
			    onModalClose(true)
			    getInfoUser(idCompte)
			  } else {
			    NotificationManager.error('Une erreur s\'est produite', '', 3000);
			  }
			})
		} else {
		  NotificationManager.error('Erreur saisie dans les champs', '', 3000);
		}
	}

	return (
	  <>
	    <Modal size="md" active={IsModalOpened} toggler={() => {return}}>
	      <ModalHeader toggler={() => onModalClose(false)}>
	          Modifier utilisateur
	      </ModalHeader>
	      <ModalBody>
	        <div style={{ textAlign: 'center' }}>
	          <div className="mb-6 grid grid-cols-2 gap-4">
	            <label className="col-span-2 block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
	              Modification utilisateur
	            </label>
	            <input className="col-span-2 border border-inherit p-2 rounded" value={pseudo} type="text" placeholder="Pseudo" onChange={(e) => setPseudo(e.target.value) }/>
	            <input className="col-span-2 border border-inherit p-2 rounded" value={codeSecret} type="text" placeholder="Code secret" onChange={(e) => setCodeSecret(e.target.value) }/>
	            <input className="col-span-2 border border-inherit p-2 rounded" value={deviceName1} type="text" placeholder="Nom device 1" onChange={(e) => setDeviceName1(e.target.value) }/>
	            <input className="col-span-2 border border-inherit p-2 rounded" value={macAdress1} type="text" placeholder="Mac adresse device 1" onChange={(e) => setMacAdress1(e.target.value) }/>
	          	<input className="col-span-2 border border-inherit p-2 rounded" value={deviceName2} type="text" placeholder="Nom device 2" onChange={(e) => setDeviceName2(e.target.value) }/>
	            <input className="col-span-2 border border-inherit p-2 rounded" value={macAdress2} type="text" placeholder="Mac adresse device 2" onChange={(e) => setMacAdress2(e.target.value) }/>
	          </div>
	        </div>
	      </ModalBody>
	      <ModalFooter>
	          <Button 
	              color="red"
	              buttonType="link"
	              onClick={(e) => onModalClose(false)}
	              ripple="dark"
	          >
	            Fermer
	          </Button>
	          <Button
	              color="green"
	              onClick={(e) => modifUser()}
	              ripple="light"
	          >
	            <EnterSend handleSubmit={handleSubmit}/>
	            Valider
	          </Button>
	      </ModalFooter>
	    </Modal>
	  </>
	);
}

export default MyModalModifUser;