import React, { useState } from 'react';

import { Card, CardHeader, CardBody } from '@material-tailwind/react';
import { Rating } from 'react-simple-star-rating'

import history from '../components/history';

import MyModalDeleteEvaluation from './EvaluationModal/MyModalDeleteEvaluation';

import axios from 'axios';

var compare1 = false
var compare2 = false

var cycleSelectedValue = null
var disciplineSelectedValue = null

export default function ClasseNoteCard({idClasse, positionEleve, suivi}) {
    const [infoNote, setInfoNote] = useState([]);
    const [infoNoteSave, setInfoNoteSave] = useState([]);
    const [allNote, setAllNote] = useState([]);
    const [allNoteSave, setAllNoteSave] = useState([]);
    const [allCycle, setAllCycle] = useState([]);
    const [allDiscipline, setAllDiscipline] = useState([]);

    const [infoNoteModal, setInfoNoteModal] = useState([]);
    const [rowModal, setRowModal] = useState([]);

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function showNote(idClasse, idSeance, detailSeance, infoNote) {
        history.push({pathname: '/note', state: { id_classe: idClasse ,id_seance: idSeance, detailSeance: detailSeance, infoNote: infoNote, positionEleve: positionEleve }});
        history.go('/note')
    }

    function openFromParent(modalNumber, infoNoteModal, rowModal) {
        switch(modalNumber) {
          case 1:
            setIsOpen(true);
            setInfoNoteModal(infoNoteModal)
            setRowModal(rowModal)
            break;
          default:
            break;
        }
    }

    function handleCloseModal(event, data) {
        switch(data) {
          case 1:
            setIsOpen(false);
            break;
          default:
            break;
        }
    }

    function parseData(data) {
        var allCycleVar = []
        var allDisciplineVar = []
        data.map((row, index) => {
            if(!allCycleVar.includes(row.id_cycle)) {
                allCycleVar.push(row.id_cycle)
            }
            if(!allDisciplineVar.includes(row.nom_sequence)) {
                allDisciplineVar.push(row.nom_sequence)
            }
            return true
        })
        setAllCycle(allCycleVar.sort())
        setAllDiscipline(allDisciplineVar.sort())
    }

    function selectFilter(value, pos) {
        var allNoteVar = []
        var allInfoNoteVar = []

        if(pos === 1) {
            cycleSelectedValue = value
        } else if(pos === 2) {
            disciplineSelectedValue = value
        }

        if (cycleSelectedValue !== null) {
            compare1 = true
        }

        if (disciplineSelectedValue !== null) {
            compare2 = true
        }

        if(compare1 === true && compare2 === true) {
            infoNoteSave.map((row, index) => {
                if((infoNoteSave[index].id_cycle.toString() === cycleSelectedValue.toString()) && (infoNoteSave[index].nom_sequence === disciplineSelectedValue)) {
                    allInfoNoteVar.push(row)
                }
                return true
            })

            allNoteSave.map((row, index) => {
                if((infoNoteSave[index].id_cycle.toString() === cycleSelectedValue.toString()) && (infoNoteSave[index].nom_sequence === disciplineSelectedValue)) {
                    allNoteVar.push(row)
                }
                return true
            })
        } else if(compare1 === true && compare2 === false) {
            infoNoteSave.map((row, index) => {
                if(infoNoteSave[index].id_cycle.toString() === cycleSelectedValue.toString()) {
                    allInfoNoteVar.push(row)
                }
                return true
            })

            allNoteSave.map((row, index) => {
                if(infoNoteSave[index].id_cycle.toString() === cycleSelectedValue.toString()) {
                    allNoteVar.push(row)
                }
                return true
            })
        } else if(compare1 === false && compare2 === true) {
            infoNoteSave.map((row, index) => {
                if(infoNoteSave[index].nom_sequence === disciplineSelectedValue) {
                    allInfoNoteVar.push(row)
                }
                return true
            })

            allNoteSave.map((row, index) => {
                if(infoNoteSave[index].nom_sequence === disciplineSelectedValue) {
                    allNoteVar.push(row)
                }
                return true
            })
        }

        setInfoNote(allInfoNoteVar)
        setAllNote(allNoteVar)
    }

    React.useEffect(() => {
        axios.get('https://eps.acces-editions.com/api/getNoteSceance?id_classe='+idClasse)
        .then((res) => {
            if(res.data[0] !== undefined)
            {
            	setInfoNote(res.data)
                setInfoNoteSave(res.data)
                parseData(res.data)
            	res.data.map((row) => {
            		return axios.get('https://eps.acces-editions.com/api/getDetailSeance?id='+row.id_seance)
        			.then((result) => {
        			     setAllNote(prev => {return [...prev, result.data.detailSeance[0]]})
        			     setAllNoteSave(prev => {return [...prev, result.data.detailSeance[0]]})
                    })
        		})
            } else {
            	setAllNote([])
            }
        })
    }, [idClasse]);

    return (
        <div>
            <MyModalDeleteEvaluation
                IsModalOpened={modalIsOpen}
                onCloseModal={handleCloseModal}
                infoNoteModal={infoNoteModal}
                rowModal={rowModal}
                idClasse={idClasse}
                key={Math.random()}
            />
            <Card className="mt-12">
                <CardHeader color="yellow" contentPosition="left">
                    <h2 className="text-white text-2xl">Tableau de bord de la classe</h2>
                </CardHeader>
                <CardBody>
                    <div className="overflow-x-auto">
                        {allCycle.length > 0 ?
                            <select onChange={(e) => selectFilter(e.target.value, 1)} class="mb-2 w-1/6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="0">Choix du cycle</option>
                                {
                                    allCycle.map((row, index) => {
                                        return( <option key={row} value={row}>{row}</option>)
                                    })
                                }
                            </select>
                            :
                            <select style={{ display: 'none'}}></select>
                        }
                        {allDiscipline.length > 0 ?
                            <select onChange={(e) => selectFilter(e.target.value, 2)} class="mb-2 w-1/6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="0">Choix séquence</option>
                                {
                                    allDiscipline.map((row, index) => {
                                        return( <option key={row} value={row}>{row}</option>)
                                    })
                                }
                            </select>
                            :
                            <select style={{ display: 'none'}}></select>
                        }
                        {allNote.length > 0 ?
                            <table className="items-center w-full bg-transparent border-collapse">
                                <thead>
                                    <tr>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Cycle
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Discipline
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Activité
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Objectif
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Moyenne
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-center">
                                            Nombre d’élèves évalués 
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                     {allNote.sort((a, b) => a.id > b.id ? 1 : -1).map((row, index) => {
                                        return (
                                            <tr key={row.id+"_"+index+"_child"} id={row.id+"_"+index+"_child"}>
                                                <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    { infoNote[index].id_cycle }
                                                </td>
                                                <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    { infoNote[index].nom_sequence }
                                                </td>
                                                <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    { row.titre_activite }
                                                </td>
                                                <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    { row.objectif }
                                                </td>
                                                <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    <div style={{ display: 'inline' }}>
                                                        <Rating initialValue={infoNote[index].totalNote / infoNote[index].nbNote}
                                                                size={20}
                                                                label
                                                                transition
                                                                fillColor='orange'
                                                                emptyColor='gray'
                                                                className='foo'
                                                                iconsCount={4}
                                                                readonly={true} />
                                                    </div>
                                                </td>
                                                <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-center">
                                                    { infoNote[index].nbNote }
                                                </td>
                                                <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    <div>
                                                        <div className="ml-10 w-10 h-10 rounded-full border-2 border-white" style={{ display: 'grid', height: 'auto', width: 'fit-content' }}>
                                                            <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded" style={{ width: '100%' }} onClick={(e) => {e.preventDefault(); showNote(idClasse ,row.id, row, infoNote[index].nbNote, positionEleve)}}>Voir l'évaluation</button>
                                                            <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mt-2" style={{ width: '100%' }} onClick={(e) => {openFromParent(1, infoNote[index], row)}}>Supprimer l'évaluation</button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        :
                            <span>Vous n'avez aucune notation</span>
                        }
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
