import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from '@material-tailwind/react';


import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import EnterSend from "../EnterSend";

import { useDispatch } from 'react-redux'

import { NotificationManager } from 'react-notifications';

import axios from 'axios';

function MyModalCreateEleve({IsModalOpened, onCloseModal, classeInfo}) {

  function onModalClose(event) {
    onCloseModal(event, 1);
  }

  const dispatch = useDispatch()

  const animatedComponents = makeAnimated();

  const [key, setKey] = React.useState(0);
  const [nomEleve, setNomEleve] = React.useState('');
  const [prenomEleve, setPrenomEleve] = React.useState('');
  const [niveauClasse, setNiveauClasse] = React.useState([]);
  
  const handleSubmit = () => {
    if(IsModalOpened === true) {
      saveModif()
    }
  };

  function saveModif() {
    if(niveauClasse === undefined || prenomEleve === undefined || niveauClasse === '' || prenomEleve === '') {
      NotificationManager.error('Vous devez remplir tout les champs', '', 3000);
      return
    }
    
    const formData = new FormData();

    formData.append('id_classe', classeInfo.idClasse)
    formData.append('nom', nomEleve)
    formData.append('prenom', prenomEleve)
    formData.append('niveau', niveauClasse)

    axios.post('https://eps.acces-editions.com/api/createEleve', formData)
    .then((res) => {
      axios.get('https://eps.acces-editions.com/api/getEleve?id_classe='+classeInfo.idClasse)
      .then((res) => {
        if(res.data[0] !== undefined && res.data[0].id_eleve)
        {
          NotificationManager.success('Élève créée', '', 3000);
          dispatch({ type: 'user/eleveDetail', payload: res.data })
          dispatch({ type: 'user/eleveNb', payload: { nb_eleve: res.data.length }})
          setKey(key + 1)
          onModalClose(false);
        }
      })
    })
  }

  var classeLevel = classeInfo.niveau ? classeInfo.niveau.split(',') : '';
  var niveauClasseOption = []
  for (var i = 0; classeLevel.length > i; i++) {
    niveauClasseOption.push({value: classeLevel[i].toString().trim(), label: classeLevel[i].toString().trim()})
  }

  return (
    <>
      <Modal key={'createEleve_'+key} size="sm" active={IsModalOpened} toggler={() => {return}}>
        <ModalHeader toggler={() => onModalClose(false)}>
            Ajout d'un élève
        </ModalHeader>
        <ModalBody>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Prénom de l'élève
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type='text' onInput={(e) => { setPrenomEleve(e.target.value)} } />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Nom de l'élève
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type='text' onInput={(e) => { setNomEleve(e.target.value)} } />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Niveau
            </label>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti={false}
              options={niveauClasseOption}
              onChange={(e) => {setNiveauClasse(e.value)}}
            />
          </div>
        </ModalBody>
        <ModalFooter>
            <Button 
                color="red"
                buttonType="link"
                onClick={(e) => onModalClose(false)}
                ripple="dark"
            >
                Fermer
            </Button>

            <Button
                color="green"
                onClick={(e) => saveModif()}
                ripple="light"
            >
              <EnterSend handleSubmit={handleSubmit}/>
              Créer
            </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default MyModalCreateEleve;