import React from 'react';
import {Card, CardHeader, CardBody, Button } from '@material-tailwind/react';
import MyModalModifNote from './NoteModal/MyModalModifNote';
import ReactTooltip from "react-tooltip";

import { Rating } from 'react-simple-star-rating'

import { useSelector } from 'react-redux'

import MyModalDeleteEvaluationEleve from './EvaluationModal/MyModalDeleteEvaluationEleve';

import moment from 'moment'
import 'moment/locale/fr'

export default function ClasseNoteEleveCard({positionEleve}) {
    const [rating, setRating] = React.useState(0) // initial rating value
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalIsOpen2, setIsOpen2] = React.useState(false);
    const [infoNote, setInfoNote] = React.useState('');

    const listeEleve = useSelector(state => state.noteDetail.arr)
    var eleve = listeEleve
    var position = positionEleve !== undefined && positionEleve !== null ? positionEleve.trim().split(',').map( Number ) : ''

    const sortEleves = (array, sortArray) => {
      return [...array].sort(
        (a, b) => sortArray.indexOf(a.id_eleve) - sortArray.indexOf(b.id_eleve)
      )
    }

    eleve = sortEleves(eleve, position)

    // Catch Rating value
    const handleRating = (rate) => {
        setRating(rate)
        // Some logic
    }

    function openFromParent(modalNumber, row) {
        switch(modalNumber) {
          case 1:
            setIsOpen(true);
            setInfoNote(row);
            break;
          case 2:
            setIsOpen2(true);
            setInfoNote(row);
            break;
          default:
            break;
        }
    }

    function handleCloseModal(event, data) {
        switch(data) {
          case 1:
            setIsOpen(false);
            break;
          case 2:
            setIsOpen2(false);
            break;
          default:
            break;
        }
    }

    return (
        <div>
            <Card className="mt-12">
                <MyModalModifNote
                    IsModalOpened={modalIsOpen}
                    onCloseModal={handleCloseModal}
                    infoNote={infoNote}
                    key={Math.random()}
                />
                <MyModalDeleteEvaluationEleve
                    IsModalOpened={modalIsOpen2}
                    onCloseModal={handleCloseModal}
                    infoNote={infoNote}
                    key={Math.random()}
                />
                <CardHeader color="yellow" contentPosition="left">
                    <h2 className="text-white text-2xl">Évaluation des élèves</h2>
                </CardHeader>
                <CardBody>
                    <div className="overflow-x-auto">
                        {eleve.length > 0 ? 
                            <table className="items-center w-full bg-transparent border-collapse">
                                <thead>
                                    <tr>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Prénom
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Nom
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Niveau
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 whitespace-nowrap font-light text-left" style={{ display: '-webkit-inline-box', lineHeight: '2.25rem', width: '-webkit-fill-available' }}>
                                            Évaluation
                                            <div>
                                                <Button data-tip data-for="registerTip" size="xs" style={{ marginLeft: '10px' }}>
                                                    ?
                                                </Button>
                                                <ReactTooltip id="registerTip" place="top" effect="solid">
                                                    <div className="grid grid-cols-2">
                                                        <div className="col-span-2">
                                                            <div>
                                                                <Rating initialValue={0} size={20} iconsCount={4} fillColor='orange' emptyColor='gray' className='foo' readonly={true} />
                                                                <span>Non évalué</span>
                                                            </div>
                                                            <div>
                                                                <Rating initialValue={1} size={20} iconsCount={4} fillColor='orange' emptyColor='gray' className='foo' readonly={true} />
                                                                <span>Non atteint</span>
                                                            </div>
                                                            <div>
                                                                <Rating initialValue={2} size={20} iconsCount={4} fillColor='orange' emptyColor='gray' className='foo' readonly={true} />
                                                                <span>Partiellement atteint</span>
                                                            </div>
                                                            <div>
                                                                <Rating initialValue={3} size={20} iconsCount={4} fillColor='orange' emptyColor='gray' className='foo' readonly={true} />
                                                                <span>Atteint</span>
                                                            </div>
                                                            <div>
                                                                <Rating initialValue={4} size={20} iconsCount={4} fillColor='orange' emptyColor='gray' className='foo' readonly={true} />
                                                                <span>Dépassé</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ReactTooltip>
                                            </div>
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                            Date
                                        </th>
                                        <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                     {eleve.map((row, index) => {
                                        return (
                                            <tr key={row.nom + Math.random()}>
                                                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    {row.prenom}
                                                </th>
                                                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    {row.nom}
                                                </th>
                                                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    {row.niveau}
                                                </th>
                                                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    <div style={{ display: 'inline' }}>
                                                        <Rating onClick={handleRating}
                                                                ratingValue={rating}
                                                                initialValue={row.note > 0 ? row.note : 0}
                                                                size={20}
                                                                label
                                                                iconsCount={4}
                                                                transition
                                                                fillColor='orange'
                                                                emptyColor='gray'
                                                                className='foo'
                                                                readonly={true} />
                                                    </div>
                                                </th>
                                                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    {moment(row.date_note).format('DD MMMM YYYY')}
                                                </th>
                                                <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                    <div>
                                                        <div className="ml-10 w-10 h-10 rounded-full border-2 border-white" style={{ display: 'grid', height: 'auto', width: 'fit-content' }}>
                                                            <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-4 mb-1 rounded" style={{ width: '100%' }} onClick={(e) => {openFromParent(1, row)}}>Modifier</button>
                                                            <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 mr-4 mb-1 rounded" style={{ width: '100%' }} onClick={(e) => {openFromParent(2, row)}}>Supprimer</button>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        :
                            <span>Vous n'avez aucune notation</span>
                        }
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}
