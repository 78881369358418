import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from '@material-tailwind/react';

import EnterSend from "../EnterSend";

import { NotificationManager } from 'react-notifications';

import axios from 'axios';

function MyModalDeleteUser({IsModalOpened, onCloseModal, userInfo, getInfoUser, indexSelected}) {

  const [idCompte, setIdCompte] = React.useState('');
  const [idUser, setIdUser] = React.useState('');

  const handleSubmit = () => {
    if(IsModalOpened === true) {
      deleteUser()
    }
  }

  function onModalClose(event) {
    onCloseModal(event, 5);
  }

  React.useEffect(() => {
    if(indexSelected !== undefined && indexSelected !== null) {
      setIdCompte(userInfo[indexSelected].id_compte)
      setIdUser(userInfo[indexSelected].id_user)
      return true
    }
  }, [indexSelected, userInfo]);

  function deleteUser() {
    if(idCompte !== '') {
      const formData = new FormData();
      formData.append('id_compte', idCompte)
      formData.append('id_user', idUser)

      axios.post('https://eps.acces-editions.com/api/deleteUser', formData)
      .then((res) => {
        if(res.status === 200)
        {
          NotificationManager.success('Utilisatur supprimé', '', 3000);
          onModalClose(true)
          getInfoUser(idCompte)
        } else {
          NotificationManager.error('Une erreur s\'est produite', '', 3000);
        }
      })
    } else {
      NotificationManager.error('Erreur saisie dans les champs', '', 3000);
    }
  }

  return (
    <>
      <Modal size="md" active={IsModalOpened} toggler={() => {return}}>
        <ModalHeader toggler={() => onModalClose(false)}>
          Suppression utilisateur
        </ModalHeader>
        <ModalBody>
          <div style={{ textAlign: 'center' }}>
            <div className="mb-6 grid grid-cols-2 gap-4">
              <label className="col-span-2 block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                Suppression utilisateur
              </label>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
            <Button 
                color="red"
                buttonType="link"
                onClick={(e) => onModalClose(false)}
                ripple="dark"
            >
              Fermer
            </Button>
            <Button
                color="green"
                onClick={(e) => deleteUser()}
                ripple="light"
            >
              <EnterSend handleSubmit={handleSubmit}/>
              Valider
            </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default MyModalDeleteUser;