const initialState = { eleve: {nb_eleve: 0 }}

export default function eleveNbReducer(state = initialState, action) {
  switch (action.type) {
    case 'user/eleveNb': {
      // Can return just the new todos array - no extra object around it
      return {
        ...state,
        eleve: {
          nb_eleve: action.payload.nb_eleve,
        }
      }
    }
    default:
      return state
  }
}