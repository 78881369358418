import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux'

function Logout() {
    const cookies = new Cookies();
    const dispatch = useDispatch()

    cookies.remove('authenticated')
    cookies.remove('authenticated_admin')

    dispatch({ type: 'LOGOUT' })
    localStorage.clear();
    window.location.href = '/';
    return null
}

export default Logout;
