import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from '@material-tailwind/react';


import { NotificationManager } from 'react-notifications';

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import { useSelector, useDispatch } from 'react-redux'

import EnterSend from "./EnterSend";

import axios from 'axios';

function MyModalComponent(props) {

  function onModalClose(event) {
    props.onCloseModal(event, 1);
  }

  function importAll(r) {
    let images = [];
    r.keys().sort((a, b) => {
        const numA = parseInt(a.match(/\d+/), 10);
        const numB = parseInt(b.match(/\d+/), 10);
        
        return numA - numB;
    }).forEach((item) => {
        images.push(r(item));
    });
    
    return images;
  }

  const images = importAll(require.context('../assets/img/avatars', false, /\.(png|jpe?g|svg)$/))

  const [pseudo, setPseudo] = React.useState();
  const [idAvatar, setIdAvatar] = React.useState();
  const [codePin, setCodePin] = React.useState(0);

  const handleSubmit = () => {
    if(props.IsModalOpened === true) {
      saveModif(pseudo, idAvatar, codePin)
    }
  };

  React.useEffect(() => {
    setPseudo(props.user.pseudo)
    setIdAvatar(props.user.id_avatar)
  }, [props]);

  const user = useSelector(state => state.userGlobal.user)
  const dispatch = useDispatch()

  function saveModif(pseudo, id_avatar, code_pin) {
    const formData = new FormData();
    formData.append('id_compte', user.id_compte)
    formData.append('id_user', props.user.id_user)

    axios.post('https://eps.acces-editions.com/api/matchPin', formData)
    .then((res) => {
        if(res.data.results[0] !== undefined && res.data.results[0].pin_code)
        {
            if(res.data.results[0].pin_code.toString() === code_pin.toString()) {
              formData.append('id_avatar', id_avatar)
              formData.append('pseudo', pseudo)
              axios.post('https://eps.acces-editions.com/api/updateUserInfo', formData)
              .then((res) => {
                axios.get('https://eps.acces-editions.com/api/showUser?id_compte='+user.id_compte)
                .then((res) => {
                    if(res.data.allPseudo[0] !== undefined && res.data.allPseudo[0].pseudo)
                    {
                      NotificationManager.success('Utilisateur mis à jour', '', 3000);
                      dispatch({ type: 'user/userDetail', payload: res.data.allPseudo })
                      onModalClose(false)
                    }
                })
                dispatch({ type: 'user/userConnected', payload: {id_user: props.user.id_user, connected: true, id_avatar: id_avatar, pseudo: pseudo} })
              })
            } else {
              NotificationManager.error('Code secret invalide', '', 3000);
            }
        }
    })
  }

  return (
    <div>
      <Modal size="sm" active={props.IsModalOpened} toggler={() => {return}}>
        <ModalHeader toggler={() => onModalClose(false)}>
            Modifier les informations
        </ModalHeader>
        <ModalBody>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Pseudo
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type='text' defaultValue={pseudo} onChange={(e) => { setPseudo(e.target.value)}} />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Avatar
            </label>
            <div>
            <Swiper
              spaceBetween={50}
              slidesPerView={3}
              style={{ overflowX: 'scroll', scrollbarColor: 'black #EEEEEE' }}
            >
              {images.map((c, index) => {
                return <SwiperSlide key={index}><button onClick={(e) => {e.preventDefault(); setIdAvatar(index)}} style={ idAvatar === index ? {border: '2px solid lightblue', borderRadius: '5px'} : {border: 'none'}}><img key={index} src={c.default} alt="avatar" /></button></SwiperSlide>;
              })}
            </Swiper>
            </div>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Valider avec le code secret à 4 chiffres
            </label>
            <input id="codepin" maxLength="4" autoComplete="new-password" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type='password' onChange={(e) => { setCodePin(e.target.value)}} />
          </div>
        </ModalBody>
        <ModalFooter>
            <Button 
                color="red"
                buttonType="link"
                onClick={(e) => onModalClose(false)}
                ripple="dark"
            >
                Fermer
            </Button>

            <Button
                color="green"
                ripple="light"
                onClick={(e) => {saveModif(pseudo, idAvatar, codePin)}}
            >
              <EnterSend handleSubmit={handleSubmit}/>
              Sauvegarder
            </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default MyModalComponent;