import React from 'react';
import StatusCard from 'components/StatusCard';
import ClasseNoteEleveCard from 'components/ClasseNoteEleveCard';
import BackButton from '../components/BackButton.js';

import { useDispatch, useSelector } from 'react-redux'

import axios from 'axios';

export default function Note(props) {
    const dispatch = useDispatch()

    React.useEffect(() => {
        axios.get('https://eps.acces-editions.com/api/getNoteEleveSceance?id_classe='+props.location.state.id_classe+'&id_seance='+props.location.state.id_seance)
        .then((res) => {
            if(res.data[0] !== undefined)
            {
                dispatch({ type: 'note/noteDetail', payload: res.data});
            }
        })
        axios.get('https://eps.acces-editions.com/api/getNoteClasseSeanceSpec?id_classe='+props.location.state.id_classe+'&id_seance='+props.location.state.id_seance)
        .then((res) => {
            if(res.data[0] !== undefined)
            {
                dispatch({ type: 'note/eleveNbNoteDetail', payload: res.data[0].nbNote});
            }
        })
        window.scrollTo(0, 0)

    }, [props, dispatch]);

    const eleveNbNoteDetail = useSelector(state => state.eleveNbNoteDetail.eleve.nb_eleve_note);

    return (
        <>  
            <div className="md:ml-64">
            <div>
                <BackButton />
                <div className="bg-light-blue-500 pt-14 pb-28 px-3 md:px-8 h-auto">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4">
                            <StatusCard
                                color="pink"
                                icon="groups"
                                title="Nombre d'élèves évalués"
                                amount={eleveNbNoteDetail !== undefined ? eleveNbNoteDetail.toString() : ''}
                            />
                            <StatusCard
                                color="orange"
                                icon="directions_run"
                                title="Activité"
                                amount={props.location.state.detailSeance.titre_activite !== undefined ? props.location.state.detailSeance.titre_activite : ''}
                            />
                            <StatusCard
                                color="green"
                                icon="textsms"
                                title="Objectif"
                                amount={props.location.state.detailSeance.objectif !== undefined ? props.location.state.detailSeance.objectif : ''}
                            />
                        </div>
                    </div>
                </div>

                <div className="px-3 md:px-8 h-auto -mt-24">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 px-4 mb-16">
                            <ClasseNoteEleveCard positionEleve={props.location.state.positionEleve} />
                        </div>
                    </div>
                </div>

                <div className="px-3 md:px-8 h-auto">
                    <div className="container mx-auto max-w-full">
                        <div className="grid grid-cols-1 px-4 mb-16">
                        
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    );
}