const initialState = { arr: [{id_classe: '', nom_classe: '', nom_etablissement: '', niveau: '', position: ''}] }

export default function classeReducer(state = initialState, action) {
  switch (action.type) {
    case 'user/classeDetail': {
      // Can return just the new todos array - no extra object around it
      return {
        ...state,
        arr: action.payload,
      }
    }
    default:
      return state
  }
}