import React from 'react';
import axios from 'axios';
import BackButton from '../components/BackButton.js';
import Logo from 'assets/img/Logo_EPS_300.png';

import { NotificationManager } from 'react-notifications';


const SendEmailForm = ({SendEmailSubmit, DeleteAccountSubmit, mailSend, setMailSend, emailSave, setEmail}) => {
  let email;
  let code_unique;

  return (
    <div className="App">
      <header className="header">
        <BackButton />
        <div className="flex flex-wrap justify-center">
          <img
          src={Logo}
          className="mt-10 mb-10"
          alt="..."
        />
        </div>
        {mailSend === true ?
          <div className="grid justify-items-center mb-6">
            <p className="block text-blue-900 font-bold md:text-right mb-1 md:mb-0 pr-4">Entrez le code unique pour supprimer votre compte</p>
          </div>
          :
          <div className="grid justify-items-center mb-6">
            <p className="block text-blue-900 font-bold md:text-right mb-1 md:mb-0 pr-4">Supprimer mon compte ?</p>
            <p className="block text-blue-900 font-bold md:text-right mb-1 md:mb-0 pr-4">Un code unique vous sera envoyé à l'adresse e-mail suivante :</p>
          </div>
        }
      </header>

      <form className="container mx-auto">
        {mailSend === true ?
          <div>
            <div className="grid justify-items-center mb-6">
              <div className="md:w-2/3">
                <input className="bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-grey-500" id="inline-full-name" type="text" placeholder="Code unique" ref={codeUniqueValue => {
                  code_unique = codeUniqueValue;
                }} />
              </div>
            </div>
            <div className="grid justify-items-center mb-6">
              <div className="">
                <button className="shadow bg-green-500 hover:bg-green-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  DeleteAccountSubmit(emailSave, code_unique.value);
                }}>
                  Confirmer
                </button>
              </div>
            </div>
          </div>
          :
          <div>
            <div className="grid justify-items-center mb-6">
              <div className="md:w-2/3">
                <input className="bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-grey-500" id="inline-full-name" type="email" placeholder="Email" ref={loginValue => {
                  email = loginValue;
                }} />
              </div>
            </div>
            <div className="grid justify-items-center mb-6">
              <div className="">
                <button className="shadow bg-green-500 hover:bg-green-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  SendEmailSubmit(email.value, setMailSend, setEmail);
                  email.value = '';
                }}>
                  Confirmer
                </button>
              </div>
            </div>
          </div>
        }
      </form>
    </div>
  );
};

const SendEmailSubmit = (email, setMailSend, setEmail) => {
  const formData = new FormData();

  formData.append('email', email)

  if(!/.+@.+\.[A-Za-z]+$/.test(email)) {
    NotificationManager.error("Cet e-mail est invalide", '', 3000);
    return
  }

  axios.post('https://eps.acces-editions.com/api/deleteAccount', formData)
  .then((res) => {
    if(res.data.results !== undefined && res.data.results.affectedRows === 1)
    {
      NotificationManager.success("Un e-mail a été envoyé", '', 3000);
      setMailSend(true)
      setEmail(email)
    } else {
      NotificationManager.error("Une erreur c'est produit ou cet e-mail n'existe pas", '', 3000);
    }
  });
}

const DeleteAccountSubmit = (email, code_unique) => {
  const params = {
    email: email,
    code_unique: code_unique,
  };

  axios.delete('https://eps.acces-editions.com/api/deleteAccountCode', { params })
  .then((res) => {
    if(res.data !== undefined && res.data.affectedRows === 1)
    {
      NotificationManager.success("Votre compte a été supprimé", '', 3000);
    } else {
      NotificationManager.error("Une erreur c'est produit ou le code unique est incorrect", '', 3000);
    }
  });
}

const DeleteAccount = () => {
  const [mailSend, setMailSend] = React.useState(false);
  const [emailSave, setEmail] = React.useState('');

  return (
    <SendEmailForm SendEmailSubmit={SendEmailSubmit} DeleteAccountSubmit={DeleteAccountSubmit} mailSend={mailSend} setMailSend={setMailSend} emailSave={emailSave} setEmail={setEmail}/>
  );
}

export default DeleteAccount;