const initialState = { arr: [{nom: '', prenom: '', niveau: '', note: 0, id_eleve: '', id_classe: '', id_seance: '', id_cycle: '', id_sequence: ''}] }

export default function noteReducer(state = initialState, action) {
  switch (action.type) {
    case 'note/noteDetail': {
      // Can return just the new todos array - no extra object around it
      return {
        ...state,
        arr: action.payload,
      }
    }
    default:
      return state
  }
}