import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from '@material-tailwind/react';


import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import EnterSend from "../EnterSend";

import { useDispatch } from 'react-redux'

import { NotificationManager } from 'react-notifications';

import axios from 'axios';

function MyModalModifClasse({IsModalOpened, onCloseModal, eleveInfo, classeInfo}) {

  function onModalClose(event) {
    onCloseModal(event, 2);
  }

  const [idEleve, setIdEleve] = React.useState();
  const [nomEleve, setNomEleve] = React.useState();
  const [prenomEleve, setPrenomEleve] = React.useState();
  const [niveauEleve, setNiveauEleve] = React.useState();

  const dispatch = useDispatch()

  const animatedComponents = makeAnimated();

  const handleSubmit = () => {
    if(IsModalOpened === true) {
      saveModif()
    }
  };

  React.useEffect(() => {
    setIdEleve(eleveInfo.id_eleve)
    setNomEleve(eleveInfo.nom)
    setPrenomEleve(eleveInfo.prenom)
    setNiveauEleve(eleveInfo.niveau !== undefined ? { value: eleveInfo.niveau, label: eleveInfo.niveau } : [])
  }, [eleveInfo.id_eleve, eleveInfo.nom, eleveInfo.prenom, eleveInfo.niveau]);

  function saveModif() {
    if(niveauEleve === undefined || prenomEleve === undefined || niveauEleve === '' || prenomEleve === '') {
      NotificationManager.error('Vous devez remplir tout les champs', '', 3000);
      return
    }

    const formData = new FormData();
    var niveau = niveauEleve.value

    formData.append('id_eleve', idEleve)
    formData.append('nom', nomEleve)
    formData.append('prenom', prenomEleve)
    formData.append('niveau', niveau)
    
    axios.post('https://eps.acces-editions.com/api/updateEleve', formData)
    .then((res) => {
      axios.get('https://eps.acces-editions.com/api/getEleve?id_classe='+classeInfo.idClasse)
      .then((res) => {
        if(res.data[0] !== undefined && res.data[0].id_eleve)
        {
          NotificationManager.success('Élève modifié', '', 3000);
          dispatch({ type: 'user/eleveDetail', payload: res.data })
          onModalClose(false);
        }
      })
    })
  }

  var classeLevel = classeInfo.niveau ? classeInfo.niveau.split(',') : '';
  var niveauClasseOption = []
  for (var i = 0; classeLevel.length > i; i++) {
    niveauClasseOption.push({value: classeLevel[i].toString().trim(), label: classeLevel[i].toString().trim()})
  }

  return (
    <>
      <Modal size="sm" active={IsModalOpened} toggler={() => {return}}>
        <ModalHeader toggler={() => onModalClose(false)}>
            Modifier l'élève
        </ModalHeader>
        <ModalBody>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Prénom de l'élève
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type='text' defaultValue={prenomEleve} onInput={(e) => { setPrenomEleve(e.target.value)}} />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Nom de l'élève
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type='text' defaultValue={nomEleve} onInput={(e) => { setNomEleve(e.target.value) }} />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Niveau
            </label>
            <Select
              closeMenuOnSelect={false}
              value={niveauEleve}
              components={animatedComponents}
              isMulti={false}
              name="properties"
              className="basic-multi-select"
              classNamePrefix="select"
              options={niveauClasseOption}
              onChange={(e) => {setNiveauEleve(e)}}
            />
          </div>
        </ModalBody>
        <ModalFooter>
            <Button 
                color="red"
                buttonType="link"
                onClick={(e) => onModalClose(false)}
                ripple="dark"
            >
                Fermer
            </Button>

            <Button
                color="green"
                onClick={(e) => saveModif()}
                ripple="light"
            >
              <EnterSend handleSubmit={handleSubmit}/>
              Modifier
            </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default MyModalModifClasse;