const initialState = { userDevice: {id_compte: 0, pseudo: '', avatar: '', id_avatar: 0, pin_code: 0, mac_adress_1: null, mac_adress_2: null, device_name_1: null, device_name_2: null }}

export default function userDeviceReducer(state = initialState, action) {
  switch (action.type) {
    case 'user/userDevice': {
      // Can return just the new todos array - no extra object around it
      return {
        ...state,
        userDevice: {
          id_compte: action.payload.id_compte,
          pseudo: action.payload.pseudo,
          avatar: action.payload.avatar,
          id_avatar: action.payload.id_avatar,
          pin_code: action.payload.pin_code,
          mac_adress_1: action.payload.mac_adress_1,
          mac_adress_2: action.payload.mac_adress_2,
          device_name_1: action.payload.device_name_1,
          device_name_2: action.payload.device_name_2
        }
      }
    }
    default:
      return state
  }
}